type ToSecondProps = {
  hour?: number,
  minute?: number,
  second?: number
}
export const toSecond = ({ hour, minute, second }: ToSecondProps) => {
  let result = 0;
  if (hour) {
    result += (hour * 3600)
  }

  if (minute) {
    result += (minute * 60)
  }

  if (second) {
    result += second
  }
  return result
}

export const toHHmmss = ({ second }: { second: number }) => {
  var sec_num = second
  var hours = Math.floor(sec_num / 3600)
  var minutes = Math.floor(sec_num / 60) % 60
  var seconds = sec_num % 60

  return [hours, minutes, seconds]
    .map(v => v < 10 ? "0" + v : v)
    .filter((v, i) => v !== "00" || i > 0)
    .join(":")
}