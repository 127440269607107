import { altenativeRoutes, estimatedDatas, locationNames } from "./_data";
import { toSecond } from "./_function";
import { LocationType, MatrixTypes } from "./index.type";

export const adjustLocationName = (location: string) => {
  switch (location) {
    case "W11A Counter":
      return "Ward 11A";

    case "W11B Counter":
      return "Ward 11B";

    case "OCH Pharmacy":
      return "SCH"

    default:
      break;
  }
  return location
}

export const useTimeEstimator = () => {
  // Number of Vertices
  const vertices = locationNames.length;
  const matrixs: MatrixTypes = Array.from({ length: vertices }, () => Array(vertices).fill(0))
  const DEFAULT_SET_TWO_WAY = true

  const FALLBACK_ESTIMATED_TIME = toSecond({ minute: 9, second: 20 })

  const addEstimatedTime = (source: LocationType, destination: LocationType, estimated_time: number, is_two_way?: boolean) => {
    if (source === destination) {
      return
    }
    const findSourceIndex = [...locationNames].findIndex((item) => item === source);
    const findDestinationIndex = [...locationNames].findIndex((item) => item === destination);

    if (findDestinationIndex < 0 || findSourceIndex < 0) {
      return
    }


    matrixs[findSourceIndex][findDestinationIndex] = estimated_time
    if (DEFAULT_SET_TWO_WAY || is_two_way) {
      matrixs[findDestinationIndex][findSourceIndex] = estimated_time
    }
  }

  const findEstimatedTime = (source: LocationType, destination: LocationType) => {
    if (source === destination) {
      return 0
    }
    const findSourceIndex = [...locationNames].findIndex((item) => item === source);
    const findDestinationIndex = [...locationNames].findIndex((item) => item === destination);

    return matrixs[findSourceIndex][findDestinationIndex]
  }

  const displayMatrix = () => {
    // Display the adjacency matrix
    for (const row of matrixs) {
      console.log(row.join(" "));
    }
  }

  const calcEstimatedTimeByTracks = (tracks: LocationType[]) => {
    let totalTime = 0;
    for (let index = 0; index < tracks.length; index++) {
      if (index + 1 >= tracks.length) {
        break;
      }
      const source = tracks[index];
      const destination = tracks[index + 1];
      const estimated_time = findEstimatedTime(source, destination)
      totalTime += estimated_time
    }

    return totalTime
  }

  const calcEstimatedTime = (source: LocationType | string, destination: LocationType | string) => {
    // Find Direct Travel Time First
    const findDirectTime = estimatedDatas.find((item) => item.source === source && item.destination === destination)
    if (findDirectTime) {
      return findDirectTime.estimated_time
    }

    // else, Find Reversed Direct Travel Time
    if (DEFAULT_SET_TWO_WAY) {
      const findReserveDirectTime = estimatedDatas.find((item) => item.destination === source && item.source === destination)
      if (findReserveDirectTime) {
        return findReserveDirectTime.estimated_time
      }
    }

    // else, find and calc by alternative routes
    const findAlternativeRoutes = altenativeRoutes.find((item) => item.source === source && item.destination === destination);
    if (findAlternativeRoutes) {
      return calcEstimatedTimeByTracks(findAlternativeRoutes.routes)
    }

    
    // else, find and calc by reversed alternative routes
    if (DEFAULT_SET_TWO_WAY) {
      const findReversedAlternativeRoutes = altenativeRoutes.find((item) => item.destination === source && item.source === destination);
      if (findReversedAlternativeRoutes) {
        return calcEstimatedTimeByTracks(findReversedAlternativeRoutes.routes.reverse())
      }
    }

    return FALLBACK_ESTIMATED_TIME
  }

  // Initiate Direct Route
  estimatedDatas.forEach((data) => {
    addEstimatedTime(data.source, data.destination, data.estimated_time, data.is_two_way)
  })

  const findAverageTime = () => {
    let existRouteCounter = 0;
    let totalSecond = 0
    for (let index = 0; index < locationNames.length; index++) {
      const source = locationNames[index];
      for (let idx = 0; idx < locationNames.length; idx++) {
        const destination = locationNames[idx];
        if (source === destination) {
          continue;
        }
        const estTime = calcEstimatedTime(source, destination)
        existRouteCounter++;
        totalSecond += estTime
      }
    }
    return parseInt((totalSecond / existRouteCounter).toString())
  }


  return { calcEstimatedTime, displayMatrix, FALLBACK_ESTIMATED_TIME }
}