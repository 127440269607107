import TopBar from "../../components/layouts/Topbar";
import { Box, Button, Grid, Typography, Stack, TextField, Menu, MenuItem, Divider, SelectChangeEvent } from "@mui/material";
import AggregateCard from "./components/AggregateCard";
import parcel from '../../assets/parcel.png';
import MenuIcon from '@mui/icons-material/Menu';
import DeliveryBoard from "./components/DeliveryBoard";
import RobotIcon from "../../components/icons/RobotIcon";
import RobotStatus from "./components/RobotStatus";
import { cardDetails, mapDetails, generalStatus } from "../../utils/DummyData";
import LocationMarker from "./components/LocationMarker";
import Fuse from "fuse.js";
import JobBoard from "./components/JobBoard";
import { JobEnums } from "../../utils/enums/JobEnums";
import RobotMapContainer from "./components/RobotMapContainer";
import RobotList from "./components/RobotList";
import GeneralStatus from "./components/GeneralStatus";
import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import { useWebSocket } from "../../components/useWebSocket";
import axios from "axios";
import { Robot } from "../../components/RobotMap/models/Robot";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import salad from '../../../assets/salad.png'; 
import NotificationHistoryPanel from "./components/NotificationHistoryPanel";
import NotificationPopUp from "./components/NotificationPopUp";
import Sidebar from "../../components/layouts/Sidebar";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import {toggle, setTrue, setFalse} from "../../store/deliveryDashboardSlice";
import { storeMapPoints, zoomIn, zoomOut, setPickUpSelected, setDropOffSelected, setSenderLocation, setRecipientLocation, setSenderAndRecipientSelected, setCurrentFloor, resetFloor } from "../../store/map/mapSlice";
import LegendToggleIcon from '@mui/icons-material/LegendToggle';
import { toggleLegend } from "../../store/map/toggleLegendSlice";
import Notification from "./components/Notification";
import FireAlarmModal from "./components/FireAlarmModal";
import { setIsSelectedRobot, setSelectedRobot, updateJobData, updateRobotStatus } from "../../store/robot/robotSlice";
import { setIsJobSelected, setSelectedJob, updateJob } from "../../store/job/jobSlice";
import { updateField, addRecipientLocation, updateRecipientLocation, removeRecipientLocation, setRecipientLocationInForm, updateRecipientIndex, deleteRecipientLocation, callJobAddRecipient } from "../../store/job/formSlice";
import { resetClick, setNotificationHistoryPanel, setNotificationMessage, setNotificationOn, setNotificationTitle, setNotificationType } from "../../store/notification/notificationSlice";
import { setSearchQuery } from "../../store/job/searchSlice";
import { addRobot, updateRobot } from "../../store/robot/robotSlice";
import { MapDetails3, JobData2, JobDetails3, RobotData3, RobotInfo3, FormDataProps } from "../../store/types";
import InfiniteScroll from 'react-infinite-scroll-component';

import { setTotalJobNumber, setTotalPageNumber, setCurrentPageNumber, setJobsPerPage, setCurrentRobotJobsPageNumber, setTotalRobotJobs, setTotalRobotJobsPageNumber } from "../../store/page/jobPageSlice";
import dayjs from "dayjs";
import { API_URL } from "../../utils/const";
import { useListMap } from "../../hooks/map";
import { MapLevelType } from "../../components/RobotMap/RobotMap";
import { MapType as MapDataType } from "../../hooks/map.type";
import { DEFAULT_LOCATION_LIMIT } from "../../hooks/location";

interface CardData {
  title: string;
  data: string;
}

interface Pose2 {
  latitude: number;
  longitude: number;
  theta: number;
  z: number;
}

interface MapDetails2 {
  building: string;
  location_name: string;
  map: string;
  map_name: string;
  pose: Pose2;
  store_manager_id: string;
}


interface SearchQuery {
  pickUpResults: MapDetails3[];
  dropOffResults: MapDetails3[];
}


interface JobDetails2 {
  compartment_pin: string;
  id: number;
  job_type: string;
  job_id: number;
  organisation_id: string;
  load_compartment: number;
  recipient_id: string;
  recipient_location: string;
  recipient_name: string;
  robot_id: string;
  sender_id: string;
  sender_location: string;
  sender_name: string;
  timestamp: number;
  order_number: string;
  job_status: string;
  recurring_type: string;
}


interface RobotPose {
  latitude: number;
  longitude: number;
  orientation: number;
}
interface RobotStatus2 {
  battery: number;
  can_assign_job: boolean;
  is_online: boolean;
  map: string;
  map_uuid?: string;
  layout_uuid?: string;
  organisation: string;
  pose: RobotPose;
  robot_id: string;
  robot_state: string;
  robot_type: string;
  timestamp: number;
  robot_name: string;
  robot_serial_number: string;
}


interface GeneralRobotInfo {
  robot_name: string;
  robot_mac: string;
  robot_serial_number: string;
  robot_type: string;
  organisation: string;
}

interface RobotInfo {
  robotStatus?: RobotStatus2;
  jobs?: JobData;
  generalInfo: GeneralRobotInfo;
}

interface RobotsData {
  [robot_id: string]: RobotInfo;
}

interface JobData {
  [order_number: string]: JobDetails2;
}


interface CardDatas {
  title: string;
  data: number;
}

interface Cards {
  [id: string]: CardDatas;
}

const defaultJob: JobDetails2 = {
  compartment_pin: "",
  id: 0,
  job_type: "pick_up_job",
  job_id: 0,
  organisation_id: "",
  load_compartment: 0,
  recipient_id: "",
  recipient_location: "",
  recipient_name: "",
  robot_id: "",
  sender_id: "",
  sender_location: "",
  sender_name: "",
  timestamp: 0,
  order_number: "",
  job_status: "",
  recurring_type: "do_not_repeat"
}

const defaultRobot: RobotInfo = {
  robotStatus: {
    battery: 0,
    can_assign_job: true,
    is_online: true,
    map: "",
    organisation: "",
    pose: {
      latitude: 1.2785387585343386,
      longitude: 103.83483403539259,
      orientation: 0,
    },
    robot_id: "",
    robot_state: "",
    robot_type: "",
    timestamp: 0,
    robot_name: "",
    robot_serial_number: ""
  },
  jobs: {},
  generalInfo: {
    robot_name: "",
    robot_mac: "",
    robot_serial_number: "",
    robot_type: "",
    organisation: ""
  }
};

interface DashboardProps {
  onNotificationCountChange: (count: number) => void;
}

interface CardData {
  title: string;
  data: string;
  id: string;
}

type MapType = {
  building: string,
  map: string,
  map_level?: number,
  map_name?: string
}

const defaultFormValue: FormDataProps = {
  pickUp: "#01-07",
  dropOff: "",
  date: "",
  time: "Pick Up Now",
  pickupOption: "now",
  robot: "Call Nearest Robot",
  phoneNumber: "",

  recipient_name: "Kelvin",
  recipient_location: "",
  compartment_pin: "0000",
  sender_location: "",
  sender_name: "",
  job_type: "pick_up_job", //delivery_job
//   job_type: "delivery_job",
  // robot_id: "2050E7403412",
  robot_id: "",
  required_compartment: 1,
  timestamp: Date.now(),
  sender_id: "",
  // sender_id: "",
  recipient_id: "",
  recurring_type: "do_not_repeat",
  recurring_days: ["do_not_repeat"],
  organisation_id: ""
}

const Dashboard: React.FC<DashboardProps> = ({ onNotificationCountChange }) => {
  const currentDropOffIndex = useSelector((state: any) => state.form.current_index);
  const dispatch = useDispatch();
  const isOpen = useSelector((state: any) => state.deliveryDashboard.isOpen);
  const triggerFireState = useSelector((state: any) => state.fire.fireTrigger);
  const isNotificationOn = useSelector((state: any) => state.notification.isNotificationOn);
  const [defaultLocation, setDefaultLocation] = useState<MapDetails3>({
    location_name: "",
    map: [],
    pose: {
      latitude: 0,
      longitude: 0,
      theta: 0,
      z: 0
    },
    store_manager_id: ""
  });

  const audioSrc = require('../../assets/notificationSound.mp3'); // Make sure the path is correct
  const audioRef = useRef<HTMLAudioElement>(null);
  const [todayJobs, setTodayJobs] = useState<JobData>({});

  const [latestNotificationId, setLatestNotificationId] = useState<string>("");

  const profile = JSON.parse(localStorage.getItem('profile') || '{}');
  const accessToken = localStorage.getItem('accessToken');
  const [listOfLocations2, setListOfLocations2] = useState<MapDetails3[]>([]);

  const [robots, setRobots] = useState<RobotsData>({});

  const [jobs, setJobs] = useState<JobData>({});
  const [prevRobotPose, setPrevRobotPose] = useState<[number, number]>([0, 0]);
  const [curRobotPose, setCurRobotPose] = useState<[number, number]>([0, 0]);

  const [dummyNotificationHistory, setDummyNotificationHistory] = useState<JobData>({});
  // const { messages, sendMessage } = useWebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/delivery/overview/${profile.uuid}/`)

  const jobOverviewUrl = `${process.env.REACT_APP_WEBSOCKET_URL}/delivery/overview/${profile.uuid}/`;
  const [notificationCount, setNotificationCount] = useState<number>(0);

  const [cardInfo, setCardInfo] = useState<Cards>({
    totalRobots: { title: "Total Robots", data: 0 },
    idleRobots: { title: "Idle", data: 0 },
    busyRobots: { title: "Busy", data: 0 },
    offlineRobots: { title: "Offline", data: 0 }
  });

  useEffect(() => {
    console.log("checking listoflocation2: ", listOfLocations2);

  }
    , [listOfLocations2]);

  const handleMessage = useCallback((data: any) => {
    let job = data;
    console.log("Job data:", job);
    // update selectedjob2
    if (selectedJob2 && selectedJob2.order_number === job.order_number) {
      setSelectedJob2(job);
    }

    // const updateJobs = (jobs: JobData, newJob: JobDetails2) => {
    //     const updatedJobs = {
    //         ...jobs,
    //         [newJob.order_number]: newJob
    //     };
    //     const sortedJobKeys = Object.keys(updatedJobs)
    //         .sort((a, b) => updatedJobs[b].timestamp - updatedJobs[a].timestamp); // Sort by most recent first
    //     if (sortedJobKeys.length > 10) {
    //         sortedJobKeys.length = 10; // Keep only the 10 most recent jobs
    //     }

    //     return sortedJobKeys.slice(0, 10).reduce<JobData>((acc, key) => {
    //         acc[key] = updatedJobs[key];
    //         return acc;
    //     }, {});
    // };

    const updateJobs = (jobs: JobData, newJob: JobDetails2) => {
      const updatedJobs = {
        ...jobs,
        [newJob.order_number]: newJob
      };
      return updatedJobs;
    }

    setJobs(prevJobs => updateJobs(prevJobs, job));
    setTodayJobs(prevJobs => updateJobs(prevJobs, job));

    setRobots(prevRobots => {
      const robotId = job.robot_id;
      const newJobId = job.order_number;
      const newJobData = data;
      console.log("Robot ID:", robotId);
      console.log("Job ID:", newJobId);
      console.log("New Job Data:", newJobData);

      if (robotId in prevRobots) {
        return {
          ...prevRobots,
          [robotId]: {
            ...prevRobots[robotId],
            jobs: {
              ...prevRobots[robotId].jobs,
              [newJobId]: job
            }
          }
        };
      } else {
        return prevRobots;
      }
    });

    dispatch(updateJob({ [job.order_number]: job }));
    if (data.robot_id) {
      dispatch(updateJobData({ [job.order_number]: job }));
    }
  }, []);

  const handleRobotStatus = useCallback((data: any) => {
    // Update robot state
    setRobots(prevRobots => ({
      ...prevRobots,
      [data.robot_id]: {
        ...prevRobots[data.robot_id],
        robotStatus: {
          ...prevRobots[data.robot_id]?.robotStatus,
          ...data,
          timestamp: Date.now()  // Ensuring timestamp is updated
        }
      }
    }));

    // dispatch (updateRobot(robots));
    dispatch(updateRobotStatus(data));

    console.log("Robot pose:", data.pose);
    setPrevRobotPose(curRobotPose);
    setCurRobotPose([data.pose.longitude, data.pose.latitude]);
  }, []);

  useEffect(() => {
    console.log("Robot Jobs:", robots);
    if (selectedRobot3 && selectedRobot3.robotStatus?.robot_id) {
      console.log("Selected Robot:", selectedRobot3);
      setSelectedRobot3(robots[selectedRobot3.robotStatus.robot_id]);
    }

    // update card info
    const totalRobots = Object.keys(robots).length;
    const idleRobots = Object.values(robots).filter(robot => robot.robotStatus?.robot_state?.toLowerCase() === "idle").length;
    const busyRobots = Object.values(robots).filter(robot => robot.robotStatus?.robot_state?.toLowerCase() === "busy").length;
    const offlineRobots = Object.values(robots).filter(robot => !robot.robotStatus?.is_online).length;
    setCardInfo({
      totalRobots: { title: "Total Robots", data: totalRobots },
      idleRobots: { title: "Idle", data: idleRobots },
      busyRobots: { title: "Busy", data: busyRobots },
      offlineRobots: { title: "Offline", data: offlineRobots }
    });
  }, [robots]);

  useEffect(() => {
    //     console.log("Jobs Dashboard:", jobs);
    if (selectedJob2 && selectedJob2.order_number !== "") {
      console.log("Selected Job:", selectedJob2);
      if (jobs[selectedJob2.order_number]) {
        setSelectedJob2(jobs[selectedJob2.order_number]);
      }
    }
  }, [jobs]);

  useWebSocket(jobOverviewUrl, handleMessage);
  useWebSocket(`${process.env.REACT_APP_WEBSOCKET_URL}/robot/${profile.organisation}/`, handleRobotStatus);

  const contentRef = useRef<HTMLDivElement>(null);
  const [imageHeight, setImageHeight] = useState<number>(0);
  const [timeFocused, setTimeFocused] = useState<boolean>(false);
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isPickUpFocused, setIsPickUpFocused] = useState<boolean>(false);
  const [isDropOffFocused, setIsDropOffFocused] = useState<boolean>(false);

  const [fieldCompleted, setFieldCompleted] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [showRobotStatus, setShowRobotStatus] = useState<boolean>(false);

  const [isJobTab, setIsJobTab] = useState<boolean>(false);
  const [showRobotList, setShowRobotList] = useState<boolean>(false);

  const [showGeneralStatus, setShowGeneralStatus] = useState<boolean>(false);
  const [hasMoreRobotJobs, setHasMoreRobotJobs] = useState<boolean>(true);

  // const [selectedRobot, setSelectedRobot] = useState<Robot>(new Robot("", initialLatLng, robotDetails));
  const [selectedRobot3, setSelectedRobot3] = useState<RobotInfo>(defaultRobot);

  const [lastActiveComponent, setLastActiveComponent] = useState('');
  const [searchValue, setSearchValue] = useState("");
  const [searchRobotValue, setSearchRobotValue] = useState("");
  const [form, setForm] = useState<FormDataProps>({
    ...defaultFormValue,
    sender_location: defaultLocation.location_name != undefined ? defaultLocation.location_name : "",
    sender_name: `${profile.username}`,
    robot_id: "",
    timestamp: Date.now(),
    sender_id: `${profile.uuid}`,
    organisation_id: `${profile.organisation}`
  });
  useEffect(() => {
    console.log("default location", defaultLocation);
  }, [defaultLocation]);

  const [selectedJob2, setSelectedJob2] = useState<JobDetails2>(defaultJob);
  const [searchResults, setSearchResults] = useState<SearchQuery>({ pickUpResults: [], dropOffResults: [] });
  const [activeStep, setActiveStep] = useState(0);

  const { data: dataMaps, isLoading: isLoadingMap } = useListMap()


  useEffect(() => {
    if (showGeneralStatus) {
      setShowRobotList(false);
      setShowRobotStatus(false);
      setIsJobTab(false);
    }

  }, [showGeneralStatus]);

  useEffect(() => {
    if (showRobotList || showRobotStatus || isJobTab) {
      setShowGeneralStatus(false);
    }
    if (isJobTab) {
      getJobList();
    }
  }, [showRobotList, showRobotStatus, isJobTab]);

  const handleShowGeneralStatus = () => {
    setShowGeneralStatus(!showGeneralStatus);
  }


  const handleSelectedJob2 = (job: JobDetails2) => {
    console.log("Selected Job:", job)
    setSelectedJob2(job);
    setSearchResults({ pickUpResults: [], dropOffResults: [] });
    // console.log(job);
    // setIsJobTab(true);

    dispatch(setSelectedJob(job));
    dispatch(setIsJobSelected(true));

    if (showRobotStatus) {
      setShowRobotStatus(false);
      setIsJobTab(true);
      setLastActiveComponent("RobotStatus");
    }
    if (isJobTab) {
      setLastActiveComponent("JobBoard");
    }
  }

  useEffect(() => {
    console.log("Selected Job:", selectedJob2);
  }, [selectedJob2]);


  const fuse = new Fuse(listOfLocations2, {
    keys: ['location_name'],
    threshold: 0.3
  });

  const getJobHistory = async (order_number: string) => {
    console.log("Getting jobs history...", order_number);
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job_logs/?order_number=${order_number}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        console.log("response history", response.data);
        const jobData = response.data.data[0];
        setSelectedJob2(jobData);
        dispatch(setSelectedJob(jobData));
      }
    }
    ).catch((error) => {
      console.error(error);
    });
  }


  const handleNotificationSelection = (value: string, type: string) => {
    dispatch(setNotificationHistoryPanel(false));
    if (type === "job") {
      // const job = jobs[value];
      // setSelectedJob2(job);
      getJobHistory(value);

      // dispatch(setSelectedJob());
      dispatch(setIsJobSelected(true));
      setLastActiveComponent("JobBoard");
      setIsJobTab(true);
      dispatch(resetClick());
    }
    else if (type === "robot") {
      const robot = robots[value];
      getRobotJobs(value);
      setSelectedRobot3(robot);
      dispatch(setSelectedRobot(robot));
      dispatch(setIsSelectedRobot(true));
      setShowRobotStatus(true);
      setLastActiveComponent("RobotStatus");
      dispatch(resetClick());
    }
  }

  const searchLocations = (query: string) => {
    console.log("Searching locations...", form.sender_location, form.recipient_location);
    if (query.length > 0) {
      const results = fuse.search(query);
      const filteredResults = results.map(result => result.item);
      console.log("Filtered results:", filteredResults);
      console.log("Query:", query);
      if (isPickUpFocused) {
        setSearchResults({ pickUpResults: filteredResults, dropOffResults: [] });
        console.log("Pickup focused", filteredResults);

      } else if (isDropOffFocused) {
        setSearchResults({ pickUpResults: [], dropOffResults: filteredResults });
        console.log("Dropoff focused", filteredResults);
      }
    } else {
      if (isPickUpFocused) {
        setSearchResults({ pickUpResults: listOfLocations2, dropOffResults: [] });
      } else if (isDropOffFocused) {
        setSearchResults({ pickUpResults: [], dropOffResults: listOfLocations2 });
      }
    }
  };

  const resetForm = () => {
    // reset form
    const defaultForm: FormDataProps = {
      ...defaultFormValue,
      recipient_name: profile.username,
      compartment_pin: "0000",
      sender_location: defaultLocation.location_name != undefined ? defaultLocation.location_name : "",
      timestamp: Date.now(),
      sender_id: `${profile.uuid}`,
      // sender_id: "",
      recipient_id: "",
      organisation_id: `${profile.organisation}`
    }
    setForm(defaultForm);
  }

  useEffect(() => {
    if (isSubmitted) {
      dispatch(setSenderLocation(""));
      dispatch(setRecipientLocation(""));
      console.log(form, "Form submitted");

      console.log("Resetting form...");
      resetFocus();
      resetForm();
      setFieldCompleted(false);
    }
  }, [isSubmitted]);

  const handleRecipientLocationChange = (value: string, index: number) => {
    console.log("Recipient Location Change", value, index);
    dispatch(updateRecipientLocation({ index, value }));
    searchLocations(value);
  };

  const assignJob = async (event: React.FormEvent<HTMLFormElement>) => {

    console.log("form", form);
    event.preventDefault();
    let apiURL = `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job/schedule_robot/`;

    //Check if its not pickup now (select time), then set the timestamp to the selected time
    if (["later"].includes(form.pickupOption || "") && form.time) {
      const dateFormat = "YYYY-MM-DD"
      const date = (form.date || dayjs().format(dateFormat))
      const currentDatetime = dayjs(date + " " + form.time, dateFormat + " " + "HH:mm")
      form.timestamp = currentDatetime.valueOf()
    }
    // if repeat the use recurring jobs
    if (!["", "do_not_repeat"].includes(form.recurring_type)) {
      apiURL = `${API_URL}v1/rms/recurring_job/`
    }

    if (form.robot_id !== "") {
      console.log("Assigning job to robot...");
      apiURL = `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/job/assign_robot/`;
    }
    await axios({
      method: "POST",
      // url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/order/submit_order/`,
      url: apiURL,
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
      data: form,
    })
      .then((response) => {
        console.log(response.data);
        if ([200, 201].includes(response.status)) {
          console.log("starting job", response.data);
          setIsSubmitted(true);
          // setSelectedJob2(response.data);
          // setSelectedJob3(response.data);
          setShowRobotStatus(false);
          setIsJobTab(true);
          dispatch(setSelectedJob(response.data));
          dispatch(setIsJobSelected(true));
          setTimeout(() => {
            setIsSubmitted(false);
            dispatch(setFalse());
          }, 5000);
        }
      })
      .catch(function (error) {
        console.log("error", error.response.data);
      });
  }

  const getRecurringJobs = async (pageNumber: number = 1) => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/recurring_job/?page=${pageNumber}&page_size=10`,
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        console.log("response recurring jobs", response.data);
        dispatch(setTotalJobNumber(response.data.count));
        dispatch(setTotalPageNumber(response.data.num_pages));
        dispatch(setCurrentPageNumber(response.data.current_page));
        const jobsData = response.data.data.reduce((acc: JobData, job: JobDetails2) => {
          acc[job.order_number] = job;
          return acc;
        }, {});
        console.log("Jobs Data:", jobsData);

        setHasMoreJobs(response.data.current_page < response.data.num_pages)
        if (response.data.current_page > 1) {
          const prevJobData = {
            ...jobs,
            ...jobsData
          }
          setJobs(prevJobData);
          return
        }
        setJobs(jobsData);
      }
    }
    ).catch((error) => {
      console.error(error);
    });
  }


  const queryMapLocations = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/location/?page_size=${DEFAULT_LOCATION_LIMIT}`,
      headers: {
        "Authorization": `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        // setListOfLocations(response.data);
        setListOfLocations2(response.data.data);
        dispatch(storeMapPoints(response.data.data));
        const getLocationID = profile.location_id;
        const defaultLocation = response.data.data.filter((location: any) => location.id === getLocationID);
        console.log("Default Location1:", defaultLocation);
        if (defaultLocation.length > 0) {
          setDefaultLocation(defaultLocation[0]);
          setForm(prevForm => ({
            ...prevForm,
            sender_location: defaultLocation[0].location_name
          }));
        }
        // setForm(prevForm => ({
        //     ...prevForm,
        //     sender_location: defaultLocation[0].location_name
        // }));
      }
    }
    ).catch((error) => {
      console.error(error);
    });
  }

  const [profileLoaded, setProfileLoaded] = useState(false);
  useEffect(() => { 
    console.log("profile", profile);
    if (profile !== null && profile !== undefined) {
      setProfileLoaded(true);
    }
  }, [profile]);

  const robotJobPageNumber = useSelector((state: any) => state.jobPage.currentRobotJobsPageNumber);

  const getRobotJobs = async (robotId: string, page: number = 1) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/rms/assigned_job/?robot_id=${robotId}&page=${page}&page_size=10`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });

      if (response.status === 200) {
        const newJobs = response.data.data.reduce((acc: JobData, job: JobDetails2) => {
          acc[job.order_number] = job;
          return acc;
        }, {});

        setRobots(prevRobots => ({
          ...prevRobots,
          [robotId]: {
            ...prevRobots[robotId],
            jobs: {
              ...prevRobots[robotId]?.jobs,
              ...newJobs, // Merge new jobs with the existing ones
            },
          },
        }));

        dispatch(setCurrentRobotJobsPageNumber(response.data.current_page));
        dispatch(setTotalRobotJobs(response.data.count));
        dispatch(setTotalRobotJobsPageNumber(response.data.num_pages));
        console.log("fetching robot ", response.data.current_page, response.data.num_pages);
        console.log("fetching robot jobs", response.data.current_page < response.data.num_pages);
        setHasMoreRobotJobs(response.data.current_page < response.data.num_pages);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRobotStatus = async () => {
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/robot_status/?organisation=${profile.organisation}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        console.log("response robot jobs", response.data);
        response.data.map((robot: any) => {
          setRobots(prevRobots => ({
            ...prevRobots,
            [robot.robot_id]: {
              ...prevRobots[robot.robot_id],
              robotStatus: robot

            }
          }));
        });

        setRobotStatusQueried(true);
      }
    }
    ).catch((error) => {
      console.error(error);
    });
  }
  const pageNumber = useSelector((state: any) => state.jobPage.currentPageNumber);
  const totalPageNumber = useSelector((state: any) => state.jobPage.totalPageNumber);
  const pageSize = useSelector((state: any) => state.jobPage.jobsPerPage);

  // useEffect(() => {
  //     getJobList();
  // }, [pageNumber]);
  const [hasMoreJobs, setHasMoreJobs] = useState(true);

  const getJobList = async (status = "in_progress", page_to_load = 1) => {

    console.log("Getting job list...");
    // start_time in unix timestamp at today 12am
    const today_start_time = new Date();
    today_start_time.setHours(0, 0, 0, 0);
    const today_start_time_unix = today_start_time.getTime() / 1000;
    console.log("Today start time:", today_start_time, today_start_time_unix);
    console.log("today start time", `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/assigned_job/?page=${page_to_load}&page_size=10&status=${status}&start_time=${today_start_time_unix}`);

    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/rms/assigned_job/?page=${page_to_load}&page_size=10&status=${status}&start_time=${today_start_time_unix}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    }).then((response) => {
      console.log(response.data);
      if (response.status === 200) {
        console.log("response", response.data);
        dispatch(setTotalJobNumber(response.data.count));
        dispatch(setTotalPageNumber(response.data.num_pages));
        dispatch(setCurrentPageNumber(response.data.current_page));
        const jobsData = response.data.data.reduce((acc: JobData, job: JobDetails2) => {
          acc[job.order_number] = job;
          return acc;
        }, {});
        console.log("Jobs Data:", jobsData);

        setHasMoreJobs(response.data.current_page < response.data.num_pages)
        if (response.data.current_page > 1) {
          const prevJobData = {
            ...jobs,
            ...jobsData
          }
          setJobs(prevJobData);
          return
        }
        setJobs(jobsData);

        // setTodayJobs(todayJobsData);

        // setListOfLocations(response.data);
        // setListOfLocations2(response.data.data);
        // dispatch(storeMapPoints(response.data.data));
      }
    }
    ).catch((error) => {
      console.error(error);
    });
  }

  const notificationSelected = useSelector((state: any) => state.notification.notificationSelected);


  useEffect(() => {
    if (notificationSelected !== null) {
      console.log("Notification Selected", notificationSelected);
      const meta = JSON.parse(notificationSelected.meta);
      if (notificationSelected.notification_details.notification_type === "job_notification") {
        const order_number = meta.order_number;
        handleNotificationSelection(order_number, "job")

      }
      else if (notificationSelected.notification_details.notification_type === "robot_notification") {
        const robot_id = meta.robot_id;
        handleNotificationSelection(robot_id, "robot");
      }
    }
  }, [notificationSelected]);

  const queryRobots = async () => {
    try {
      // const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/rms/robot/?robot_organisation__uuid=${profile.organisation}`, {
      //     headers: {
      //         "Authorization": `Bearer ${accessToken}`,
      //     },
      // });
      const { data } = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/v1/rms/robot/??page_size=1`, {
        headers: {
          "Authorization": `Bearer ${accessToken}`,
        },
      });
      console.log("new robot data", data);
      const robotsData: RobotsData = data.data.reduce((acc: RobotsData, robot: any) => {
        const { robot_mac, robot_name, robot_serial_number, robot_type, organisation } = robot;
        // Initialise robot data
        acc[robot_mac] = {
          robotStatus: {
            robot_name: robot_name,
            robot_serial_number: robot_serial_number,
            battery: 0,
            can_assign_job: true,
            is_online: true,
            map: "",
            organisation: organisation,
            pose: {
              latitude: 1.2785387585343386,
              longitude: 103.83483403539259,
              orientation: 0
            },
            robot_id: robot_mac,
            robot_state: "Idle",
            robot_type: robot_type.toString(),
            timestamp: Date.now(),
          },
          jobs: {},
          generalInfo: {
            robot_name: robot_name,
            robot_mac: robot_mac,
            robot_serial_number: robot_serial_number,
            robot_type: robot_type.toString(),
            organisation: organisation
          }
        };
        return acc;
      }, {});

      const totalRobots = Object.keys(robotsData).length;
      setCardInfo({
        totalRobots: { title: "Total Robots", data: totalRobots },
        idleRobots: { title: "Idle", data: 0 },
        busyRobots: { title: "Busy", data: 0 },
        offlineRobots: { title: "Offline", data: 0 }
      });
      setRobotQueried(true);
      dispatch(addRobot(robotsData));

      setRobots(robotsData);
    } catch (error) {
      console.error("Failed to fetch robots:", error);
    }
  };

  const [robotQueried, setRobotQueried] = useState<boolean>(false);
  const [robotStatusQueried, setRobotStatusQueried] = useState<boolean>(false);

  useEffect(() => {
    if (!robotQueried) {
      getRobotStatus();
    }
  }, [robotQueried]);

  useEffect(() => {
    if (!robotStatusQueried) {
      getJobList();
    }
  }, [robotStatusQueried]);

  useEffect(() => {
    // queryMapLocations();
    // queryRobots();
    // getRobotStatus();
    // getJobList();
    // query after profile is loaded
    if (profileLoaded) {
      console.log("Profile loaded", profile);
      queryRobots();
      queryMapLocations();
    }
  }, [profileLoaded]);


  const handleTimeFocus = () => {
    setTimeFocused(true);
  };

  const handleTimeBlur = () => {
    console.log("Time blurred", form.time);
    setTimeFocused(false);
  };

  const handleIsOpen = () => {
    if (isSubmitted) {
      setIsSubmitted(false);
    }
    // setSelectedJob2(defaultJob);
    setIsJobTab(false);
    setShowRobotList(false);
    // When want to close
    if (isOpen) {
      //Reset the Focus state
      resetFocus()
      resetForm()
    }
    dispatch(toggle());
    // setIsOpen(!isOpen);
  }

  const handleStartDeliveryFocus = () => {
    setIsPickUpFocused(true);
    setIsDropOffFocused(false);
    console.log("Pickup focused");
  };

  const handleStartDeliveryBlur = (index: number) => {
    setIsDropOffFocused(true);
    setIsPickUpFocused(false);
    dispatch(updateRecipientIndex(index));
    console.log("Pickup blurred");
  }

  const resetFocus = () => {
    setIsPickUpFocused(false);
    setIsDropOffFocused(false);
  }

  const senderLocation = useSelector((state: any) => state.map.senderLocation);
  const recipientLocation = useSelector((state: any) => state.map.recipientLocation);
  const recipient_locations = useSelector((state: any) => state.form.recipient_locations);

  const handleLocationSelect = useCallback((location: string, deliveryType: string) => {
    // deliveryType === "Pick Up"
    //     ? setForm(prevState => ({ ...prevState, sender_location: location }))
    //     : setForm(prevState => ({ ...prevState, recipient_location: location }));

    if (form.pickupOption === "call_nearest" || form.job_type === "adhoc_job") {
      console.log("form updated 2: ", form);
      setForm(prevState => ({ ...prevState, recipient_location: location }));
      // handleFieldChange("recipient_location", form.recipient_location, currentDropOffIndex);
      // dispatch(callJobAddRecipient(form.sender_location));
    }
    
    console.log('LOC', location, deliveryType); 
    if (deliveryType === "Pick Up") {
      console.log("Updating sender_location in form", location, form.sender_location);
      setForm(prevState => ({ ...prevState, sender_location: location }))
      dispatch(updateField({ name: "sender_location", value: location }));
    } else {
      if (currentDropOffIndex === 0) {
        console.log("Updating recipient_location in form", currentDropOffIndex, location, form.recipient_location);
        setForm(prevState => ({ ...prevState, recipient_location: location }));
      }
      dispatch(updateField({ name: "recipient_location", value: location }));
    }

    if (deliveryType === "Drop Off") {
      console.log("Updating recipient_location in form", currentDropOffIndex, location, form.recipient_location);
      dispatch(updateRecipientLocation({ index: currentDropOffIndex, value: location }));
    }

    resetFocus();

    deliveryType === "Pick Up"
      ? dispatch(setSenderLocation(location))
      : dispatch(setRecipientLocation(location));

    senderLocation !== "" && recipientLocation !== "" ?
      dispatch(setSenderAndRecipientSelected(true))
      : dispatch(setSenderAndRecipientSelected(false));

    if (!isOpen) {
      // setIsOpen(true);
      dispatch(setTrue());
    }
  }, [setForm, resetFocus, isOpen]);

  const handleJobSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.target;
    setSearchValue(value);
  }

  const handleRobotSearchField = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    const { value } = event.target;
    setSearchRobotValue(value);
  }
  // post to backend
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("Submitting form...");
    if (form.pickupOption === "call_nearest") {
      console.log("Calling nearest robot...", form);
      // handleFieldChange("recipient_location", form.recipient_location, currentDropOffIndex);
    //   dispatch (callJobAddRecipient(form.sender_location));
      assignJob(event);
    }
    // assignJob(event);
    console.log("Calling nearest robot 2:", recipient_locations);
    recipient_locations.map((location: string, index: number) => {
      console.log("Recipient Location", index, location);
      setForm(prevState => ({
        ...prevState,
        recipient_location: location
      }));
      // if last index of recipient_locations
      if (index === recipient_locations.length - 1) {
        // reset recipient_locations
        dispatch(deleteRecipientLocation());
      }
      if (location !== "") {
        assignJob(event);
      }
    }
    );
  };

  const generalInfo = cardDetails;

  useEffect(() => {
    const updateImageHeight = () => {
      const contentHeight = contentRef.current?.offsetHeight ?? 0;
      const viewportHeight = window.innerHeight;
      console.log("Content Height:", contentHeight, "Viewport Height:", viewportHeight);
      setImageHeight(viewportHeight - contentHeight - 64);
    };

    updateImageHeight();
    window.addEventListener('resize', updateImageHeight);

    return () => window.removeEventListener('resize', updateImageHeight);
  }, []);

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Field change", event.target);
    event.preventDefault();
    let { name, value } = event.target;
    value = name === "time" || name === "robot_id" ? event.target.value : value;
    console.log(name, value, "test");
    setForm(prevState => ({
      ...prevState,
      [name]: ['recurring_days'].includes(name) ? value.split(',') : value
    }));


    if (name === "sender_location" || name === "recipient_location") {
      console.log("Searching locations...");
      searchLocations(value);
    }
  }

  useEffect(() => {
    console.log("Form updated", form);
  }
    , [form]);

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    event.preventDefault();
    const { name, value } = event.target;
    setForm(prevState => ({
      ...prevState,
      [name]: value
    }));
    console.log('SELECT CHANGE', event.target);

  }


  useEffect(() => {
    console.log ("Field completed", form);

    if (form.pickupOption === "call_nearest" || (form.sender_location && form.recipient_location)) {
      setFieldCompleted(true);
    }
    else {
        setFieldCompleted(false);
    }
  }, [form.sender_location, form.recipient_location, form.pickupOption]);

  useEffect(() => {
    if (isPickUpFocused || isDropOffFocused) {
      searchLocations("");
    }
  }, [isPickUpFocused, isDropOffFocused]);

  const selectedRobot = useSelector((state: any) => state.robot.selectedRobot);

  const onRobotClick2 = (robot: RobotInfo) => {

    dispatch(setIsSelectedRobot(true));
    dispatch(setSelectedRobot(robot));
    // console.log("Robot clicked", selectedRobot);
    console.log("Robot clicked", robot);
    setSelectedRobot3(robot);
    setShowRobotStatus(true);
    setShowRobotList(false);
    setLastActiveComponent("RobotStatus");
    setIsJobTab(false);
    if (robot.robotStatus?.robot_id) {
      console.log("Getting robot jobs...", robot.robotStatus.robot_id);
      getRobotJobs(robot.robotStatus.robot_id);
    }

    handleChangeMapByRobot(robot)
  }

  const handleChangeMapByRobot = (robot: RobotInfo) => {
    if (!robot.robotStatus || !robot.robotStatus.layout_uuid) {
      return
    }
    const findMap = getMapLevels().find((item) => item.map_uuid === robot.robotStatus?.layout_uuid)
    if (!findMap || !findMap.map_level) {
      return
    }
    const isDifferentLevel = parseInt(currentFloor) !== findMap.map_level
    if (isDifferentLevel) {
      dispatch(setCurrentFloor(findMap.map_level.toString()))
    }
  }

  const filterMapOnly = (data: MapDataType[]) => {
    if (!data || data.length === 0) {
      return []
    }

    return data.filter((item) => item.robot === '' && item.map_longitude_tl > 0 && item.map_latitude_br > 0)
  }

  const getMapLevels = () => {
    if (!dataMaps || isLoadingMap || !dataMaps.data) {
      return [] as MapLevelType[]
    }

    const results: any[] = []
    const notRobotMaps = filterMapOnly(dataMaps.data || []);
    notRobotMaps.map((item) => {
      const find = results.find((result) => result.level === item.map_level)
      if (!find) {
        results.push(item)
      }
    })

    return results
  }

  const onRobotIconClick = (robot: Robot) => {
    dispatch(setIsSelectedRobot(true));
    dispatch(setSelectedRobot(robots[robot.robotId]));

    console.log("Robot clicked", robot.robotId);
    setSelectedRobot3(robots[robot.robotId]);
    setShowRobotStatus(true);
    setShowRobotList(false);
    if (robot.robotId) {
      console.log("Getting robot jobs...", robot.robotId);
      getRobotJobs(robot.robotId);
    }
  }

  const handleJobTab = () => {
    if (showRobotStatus) {
      setShowRobotStatus(false);
      setIsJobTab(true);
      setShowRobotList(false);
      setSelectedRobot3(defaultRobot);
      setLastActiveComponent("RobotStatus");
      setShowRobotList(true);
      dispatch(setIsSelectedRobot(false));
      dispatch(setSelectedRobot(defaultRobot));
    }
    else {
      setShowRobotList(false);
      setIsJobTab(!isJobTab);
      dispatch(setIsJobSelected(false));
      dispatch(setSelectedJob(defaultJob));
    }
  }

  const handleRobotList = () => {
    if (showRobotStatus || isJobTab) {
      setShowRobotStatus(false);
      setSelectedJob2(defaultJob);
      setShowRobotList(true);
      setSelectedRobot3(defaultRobot);
      setIsJobTab(false);
    } else {
      setShowRobotList(!showRobotList);
      setSearchRobotValue("");
    }
  }

  const handleCloseRobotList = () => {
    setShowRobotList(false);
    setSearchRobotValue("");
    if (selectedRobot3.robotStatus?.robot_id) {
      //reset selected robot
      setSelectedRobot3(defaultRobot);
      dispatch(resetFloor());
    }
    dispatch(setCurrentRobotJobsPageNumber(1));
  }

  const handleCloseRobotStatus = () => {
    setShowRobotStatus(false);
    if (selectedRobot3.robotStatus?.robot_id) {
      //reset selected robot
      setSearchRobotValue("");
      setSelectedRobot3(defaultRobot);
      dispatch(resetFloor());
      dispatch(setIsSelectedRobot(false));
      dispatch(setSelectedRobot(defaultRobot));
      dispatch(setCurrentRobotJobsPageNumber(1));
    }

    setShowRobotList(false);
  }

  const handleJobBoardClose = () => {
    setSearchValue("");
    setIsJobTab(false);
    setSelectedJob2(defaultJob);
    dispatch(setIsJobSelected(false));
    dispatch(setSelectedJob(defaultJob));
    dispatch(setIsSelectedRobot(false));
    dispatch(setCurrentPageNumber(1));
  }

  const handleBackRobotStatus = () => {
    setSearchRobotValue("");
    dispatch(setIsSelectedRobot(false));
    dispatch(setSelectedRobot(defaultRobot));
    setShowRobotStatus(false);
    setSelectedRobot3(defaultRobot);
    setShowRobotList(true);
    dispatch(setCurrentRobotJobsPageNumber(1));
  }

  const handleJobBackButton = () => {
    setSelectedJob2(defaultJob);
    if (lastActiveComponent === "RobotStatus") {
      setShowRobotStatus(true);
      setIsJobTab(false);
    } else {
      setIsJobTab(true);
    }
    dispatch(setIsJobSelected(false));
    dispatch(setSelectedJob(defaultJob));
  }

  const handleDeleteNotification = (order_number: string) => {
    console.log("Deleting notification", order_number);
    const updatedNotificationHistory = { ...dummyNotificationHistory };
    delete updatedNotificationHistory[order_number];
    setDummyNotificationHistory(updatedNotificationHistory);
  }


  const renderLocationMarkers = () => {
    // ADD Filter only show the same level
    const listFilter = listOfLocations2.filter((item) => parseInt(item.map[0].map_level?.toString() || '0') === parseInt(currentFloor))

    if (isPickUpFocused) {
      return listFilter.map(location => (
        <LocationMarker GIS={[location.pose.longitude, location.pose.latitude]} key={location.location_name} name={location.location_name} x={location.pose.longitude} y={location.pose.latitude} color="#378FFE" handleLocationSelect={() => handleLocationSelect(location.location_name, "Pick Up")} />
      ));
    } else if (isDropOffFocused) {
      return listFilter.map(location => (
        <LocationMarker GIS={[location.pose.longitude, location.pose.latitude]} key={location.location_name} name={location.location_name} x={location.pose.longitude} y={location.pose.latitude} color="#FF0000" handleLocationSelect={() => handleLocationSelect(location.location_name, "Drop Off")} />
      ));
    } else if (selectedJob2?.sender_location || selectedJob2?.recipient_location) {
      console.log("Selected Job showing", selectedJob2);
      return listOfLocations2.filter(location => location.location_name === selectedJob2.sender_location || location.location_name === selectedJob2.recipient_location).map(location => (
        <LocationMarker GIS={[location.pose.longitude, location.pose.latitude]} key={location.location_name} name={location.location_name} x={location.pose.longitude} y={location.pose.latitude} color={location.location_name === selectedJob2.sender_location ? "#378FFE" : "#FF0000"} selected={true} />
      ));

    } else if (form.sender_location || recipient_locations.length > 0) {
      return listFilter.map(location => (
        <LocationMarker
          GIS={[location.pose.longitude, location.pose.latitude]}
          key={location.location_name}
          name={location.location_name}
          x={location.pose.longitude}
          y={location.pose.latitude}
          // Show Blue color if selected sender and delivery board is open
          color={isOpen && location.location_name === form.sender_location ? "#378FFE" : "#FF0000"}
          // only show big pinpoint when delivery board opened
          selected={isOpen && (form.sender_location === location.location_name || recipient_locations[0] === location.location_name || recipient_locations[1] === location.location_name || recipient_locations[2] === location.location_name)} />
      ));
    }

    else {
      console.log("Displaying all locations");
      return listOfLocations2.map(location => (
        <LocationMarker GIS={[location.pose.longitude, location.pose.latitude]} key={location.location_name} name={location.location_name} x={location.pose.longitude} y={location.pose.latitude} color="#378FFE" />
      ));
    }

  };


  const handleClearTextField = (field: string) => {
    setForm(prevState => ({
      ...prevState,
      [field]: ""
    }));
    resetFocus();
  }

  const handleSetMoreJobs = () => {
    setHasMoreJobs(true);
  }

  const getLines = (): [number, number][] => {
    // check if there is a selected job with both pick-up and drop-off locations
    console.log("Selected Job 34:", selectedJob2);
    if (selectedJob2?.order_number !== "" && selectedJob2?.sender_location && selectedJob2?.recipient_location) {
      const selectedPickUpLocation = listOfLocations2.find(loc => loc.location_name === selectedJob2.sender_location);
      const selectedDropOffLocation = listOfLocations2.find(loc => loc.location_name === selectedJob2.recipient_location);
      if (selectedPickUpLocation && selectedDropOffLocation) {
        console.log("Displaying line for selected job:", selectedJob2, selectedPickUpLocation, selectedDropOffLocation);
        return [[selectedPickUpLocation.pose.longitude, selectedPickUpLocation.pose.latitude], [selectedDropOffLocation.pose.longitude, selectedDropOffLocation.pose.latitude]];
        // return [selectedPickUpLocation.GIS, selectedDropOffLocation.GIS];
      }
    }

    // check if the form's pick-up and drop-off fields are filled and no selected job is active
    if (form.sender_location && form.recipient_location && !selectedJob2.order_number) {
      console.log("updating line for form data:", form);
      const pickUpLocation = listOfLocations2.find(loc => loc.location_name === form.sender_location);
      const dropOffLocation = listOfLocations2.find(loc => loc.location_name === form.recipient_location);
      if (pickUpLocation && dropOffLocation) {
        console.log("Displaying line for form data:", pickUpLocation, dropOffLocation);
        const pickUpGIS = [pickUpLocation.pose.longitude, pickUpLocation.pose.latitude] as [number, number];
        const dropOffGIS = [dropOffLocation.pose.longitude, dropOffLocation.pose.latitude] as [number, number];
        // return [pickUpLocation.GIS, dropOffLocation.GIS];
        return [pickUpGIS, dropOffGIS];
      }
    }

    // If no conditions are met, return an empty array
    console.log("No valid data for displaying line");
    return [];
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  }

  const notificationHistoryPanel = useSelector((state: any) => state.notification.notificationHistoryPanel);
  const currentFloor = useSelector((state: any) => state.map.currentFloor);

  /** Handler when Open Delivery Board,
   * If sender_location exist then select sender_location floor level
   *  */ 
  useEffect(() => {
    if (!isOpen || !form.sender_location) {
      return
    }

    const pickUpLocation = listOfLocations2.find(loc => loc.location_name === form.sender_location);
    if (!pickUpLocation || !pickUpLocation.map || !pickUpLocation.map[0].map_level) {
      return
    }

    const isDifferentLevel = parseInt(currentFloor) !== pickUpLocation.map[0].map_level
    if (isDifferentLevel) {
      dispatch(setCurrentFloor(pickUpLocation.map[0].map_level.toString()))
    }
  }, [isOpen])
  return (
    <div style={{ color: "white", margin: 0, width: "100%", background: "#f6f7f6", overflow: "hidden" }}>
      <div ref={contentRef}>
        <Stack direction="column" spacing={2} sx={{ width: "100%", margin: "auto", display: "flex", background: "linear-gradient(to bottom, #DAFBEF, #F8F7F7)", height: "120px", alignItems: "center", }}>
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "97%" }}>
            {/* <MenuIcon sx={{ color: "#9A9A9A", fontSize: "25px", mt:2.5, ml:2 }} /> */}
            {/* <TopBar isOpen={isOpen} handleIsOpen={handleIsOpen} handleNotificationDisplay={() => setIsDrawerOpen(true)} notificationCount={notificationCount} toggleSidebar={toggleSidebar} /> */}
          </Box>
          <Grid container spacing={2} sx={{ display: "flex", width: "97%", }}>
            {/* {cardInfo.map((item, index) => (
                            <AggregateCard key={index} title={item.title} data={item.data} clicked={false} />
                        ))} */}
            {cardInfo && Object.keys(cardInfo).map((key, index) => (
              <AggregateCard key={index} title={cardInfo[key].title} data={cardInfo[key].data} clicked={false} />
            ))}
          </Grid>
        </Stack>
      </div>
      <Stack direction="column" spacing={2} >
        <div style={{ position: 'relative', width: '100%', height: imageHeight }}>
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <RobotMapContainer onRobotClick={onRobotIconClick} line={getLines()} movement={[prevRobotPose, curRobotPose]} robotData={robots} renderLocationMarkers={renderLocationMarkers} >
              {renderLocationMarkers()}
            </RobotMapContainer>

          </Box>
        </div>
        {isOpen && (
          <DeliveryBoard
            timeFocused={timeFocused}
            handleTimeFocus={handleTimeFocus}
            handleTimeBlur={handleTimeBlur}
            handleIsOpen={handleIsOpen}
            handleStartDeliveryFocus={handleStartDeliveryFocus}
            handleStartDeliveryBlur={(index: number) => handleStartDeliveryBlur(index)}
            isPickUpFocused={isPickUpFocused}
            isDropOffFocused={isDropOffFocused}
            resetFocus={resetFocus}
            handleLocationSelect={handleLocationSelect}
            fieldCompleted={fieldCompleted}
            isSubmitted={isSubmitted}
            handleSubmit={handleSubmit}
            form={form}
            handleFieldChange={handleFieldChange}
            handleSelectChange={handleSelectChange}
            handleClearTextField={handleClearTextField}
            robots={robots}
            maps={isPickUpFocused ? searchResults.pickUpResults : isDropOffFocused ? searchResults.dropOffResults : listOfLocations2}
            handleRecipientLocationChange={handleRecipientLocationChange}
          />
        )}
        {showRobotStatus && (
          <RobotStatus showRobotStatus={showRobotStatus} handleSelectedJob={handleSelectedJob2} robotInfo={selectedRobot3 ?? {}} handleCloseTab={handleCloseRobotStatus} handleBackButton={handleBackRobotStatus} getRobotJobs={getRobotJobs} hasMoreRobotJobs={hasMoreRobotJobs} />
        )}

        {showRobotList && (
          <RobotList showRobotList={showRobotList} handleRobotList={handleRobotList} onRobotSelect={(robot: RobotInfo) => onRobotClick2(robot)} robots={robots} handleCloseRobotList={handleCloseRobotList} searchRobotValue={searchRobotValue} handleSearchRobotField={handleRobotSearchField} />
        )}
        {showGeneralStatus && (
          <GeneralStatus handleStatusBoardClose={handleShowGeneralStatus} />
        )}
        <Stack
          sx={{
            position: "absolute",
            top: "180px",
            right: "30px",
            display: "flex",
            flexDirection: "row",
            bgcolor: "white",
            padding: "8px",
            borderRadius: "4px",
            px: 2,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.13)",

          }}>
          <Typography sx={{
            fontSize: "14px",
            mr: 3,
            color: showGeneralStatus ? "#378FFE" : "#9E9E9E",
            // textShadow: `-1px -1px 0 #B0B0B0, 1px -1px 0 #B0B0B0, -1px 1px 0 #B0B0B0, 1px 1px 0 #B0B0B0`, 
            '&:hover': { cursor: "pointer", color: "#378FFE" }
          }}
            onClick={handleShowGeneralStatus}>
            Lift Status
          </Typography>
          <Divider orientation="vertical" sx={{ height: "20px", color: "#9E9E9E", mr: 3 }} />

          <Typography sx={{
            fontSize: "14px",
            mr: 3,
            color: showRobotList ? "#378FFE" : "#9E9E9E",
            // textShadow: `-1px -1px 0 #B0B0B0, 1px -1px 0 #B0B0B0, -1px 1px 0 #B0B0B0, 1px 1px 0 #B0B0B0`, 
            '&:hover': { cursor: "pointer", color: "#378FFE" }
          }}
            onClick={handleRobotList}>
            Robot Status
          </Typography>
          <Divider orientation="vertical" sx={{ height: "20px", color: "#9E9E9E", mr: 3 }} />

          <Typography sx={{
            fontSize: "14px",
            // fontWeight:"600",    
            color: isJobTab ? "#378FFE" : "#9E9E9E",
            // textShadow: `-1p x -1px 0 #B0B0B0, 1px -1px 0 #B0B0B0, -1px 1px 0 #B0B0B0, 1px 1px 0 #B0B0B0`, 
            '&:hover': { cursor: "pointer", color: "#378FFE" }
          }}
            onClick={handleJobTab}>
            Today's Jobs
          </Typography>
        </Stack>
        {isJobTab && (<JobBoard getRecurringJobs={getRecurringJobs} handleSetMoreJobs={handleSetMoreJobs} hasMoreJobs={hasMoreJobs} getJobList={getJobList} handleJobBoardClose={handleJobBoardClose} handleSelectedJob={handleSelectedJob2} selectedJob={selectedJob2} imageHeight={imageHeight} activeStep={activeStep} handleJobSearchField={handleJobSearchField} searchValue={searchValue} listOfJobs={jobs} handleBackButton={handleJobBackButton} />)}

      </Stack>


      <Stack
        sx={{
          position: "absolute",
          bottom: "30px",
          left: "40px",
        }}>
        {
          !isOpen && (
            <Button variant="contained" sx={{ width: "62px", height: "62px", bgcolor: "#2C8DFF", color: "white", fontWeight: "600", borderRadius: "31px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }} onClick={handleIsOpen}>
              <img src={parcel} style={{ width: "30px", height: "30px" }} />
            </Button>
          )
        }
      </Stack>
      <Stack
        direction={"column"}
        sx={{
          position: "absolute",
          bottom: "150px",
          left: "50px",
          width: "40px",
          height: "40px",
        }}>
        {/* <Button sx={{minWidth:"40px", minHeight:"40px", bgcolor: "rgba(187, 187, 187, 0.5)", color: "white", fontWeight:"600", borderRadius:"4px", fontSize:"16px", mb:0.2, '&:hover': { cursor:"pointer", bgcolor:"rgba(96, 96, 96, 0.62)" } }} onClick={() => {
                        dispatch(toggleLegend());
                        console.log("toggling legend, ", legendOpen);

                    }}>
                        <LegendToggleIcon />
                    </Button> */}
        <Button sx={{ minWidth: "40px", minHeight: "40px", bgcolor: "rgba(187, 187, 187, 0.5)", color: "white", fontWeight: "600", borderRadius: "4px", fontSize: "16px", mb: 0.2, '&:hover': { cursor: "pointer", bgcolor: "rgba(96, 96, 96, 0.62)" } }} onClick={() => dispatch(zoomIn())}>
          +
        </Button>
        <Button sx={{ minWidth: "40px", minHeight: "40px", bgcolor: "rgba(187, 187, 187, 0.5)", color: "white", fontWeight: "600", borderRadius: "4px", fontSize: "16px", '&:hover': { cursor: "pointer", bgcolor: "rgba(96, 96, 96, 0.62)" } }} onClick={() => dispatch(zoomOut())}>
          -
        </Button>

      </Stack>
      {/* { isNotificationOn ? 
                <>
                    <Notification/>
                    <audio ref={audioRef} src={audioSrc} autoPlay />
                </>
                : null
            } */}
      <FireAlarmModal />
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      {/* <NotificationHistoryPanel handleNotificationSelection={handleNotificationSelection} open={notificationHistoryPanel}  dummyNotificationHistory={dummyNotificationHistory} handleSelectJob={handleSelectedJob2} handleDeleteNotification={handleDeleteNotification} /> */}
      <NotificationHistoryPanel
        open={notificationHistoryPanel}
        dummyNotificationHistory={{}}
        handleSelectJob={handleSelectedJob2}
        handleDeleteNotification={handleDeleteNotification}
      // handleNotificationSelection={handleNotificationSelection} 
      />

    </div>
  );
}

export default Dashboard;
