import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import { CSSProperties } from "react";

export default function PinPointIcon({ style }: { style?: CSSProperties }) {
  return (
    <RadioButtonCheckedIcon
      style={{
        // backgroundColor: "#FF0000",
        borderRadius: "4px",
        color: "#FF0000",
        fontSize: '40px',
        ...style
      }}
    />
  )
}