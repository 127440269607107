import { Box, Stack, Typography, TextField, IconButton, List, ListItem, ListItemText, ListItemIcon, Divider, Button, Card, InputAdornment } from "@mui/material";
import { useState, useEffect } from "react";
import CloseIcon from '@mui/icons-material/Close';
import robotBusy from '../../../assets/robotBusy.png'; 
import flashbot from '../../../assets/flashbot.png'; 
import swiftbot from '../../../assets/swiftbot.png'; 

import PlaceIcon from '@mui/icons-material/Place';
import salad from '../../../assets/salad.png'; 
import checked from '../../../assets/checked.png';
import { robotDetails, listOfRobots } from "../../../utils/DummyData";
import Battery80Icon from '@mui/icons-material/Battery80';
import Battery30Icon from '@mui/icons-material/Battery30';
import Battery50Icon from '@mui/icons-material/Battery50';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import BatteryChargingFullIcon from '@mui/icons-material/BatteryChargingFull';
import { JobData2, JobDetails3 } from "../../../store/types";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Fuse from 'fuse.js';
import SearchIcon from '@mui/icons-material/Search';
import { Robot } from "../../../components/RobotMap/models/Robot";
import { useSelector } from "react-redux";
import { RobotData3, RobotInfo3 } from "../../../store/types";
interface RobotListProps {
    showRobotList: boolean;
    handleRobotList: () => void;
    handleCloseRobotList: () => void;
    onRobotSelect: (robot: RobotInfo) => void;
    robots: RobotsData;
    searchRobotValue?: string;
    handleSearchRobotField: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface JobDetails2 {
    compartment_pin: string;
    id: number;
    job_type: string;
    job_id: number;
    organisation_id: string;
    load_compartment: number;
    recipient_id: string;
    recipient_location: string;
    recipient_name: string;
    robot_id: string;
    sender_id: string;
    sender_location: string;
    sender_name: string;
    timestamp: number;
    order_number: string;
    job_status: string;
    recurring_type: string;
}

interface RobotPose {
    latitude: number;
    longitude: number;
    orientation: number;
}
interface RobotStatus2 {
    battery: number;
    can_assign_job: boolean;
    is_online: boolean;
    map: string;
    organisation: string;
    pose: RobotPose;
    robot_id: string;
    robot_state: string;
    robot_type: string;
    timestamp: number;  
    robot_name: string;
    robot_serial_number: string;

}

interface GeneralRobotInfo{
    robot_name: string;
    robot_mac: string;
    robot_serial_number: string;
    robot_type: string;
    organisation: string;
}

interface RobotInfo {
    robotStatus?: RobotStatus2;
    jobs?: JobData;
    generalInfo: GeneralRobotInfo;
}

interface RobotsData {
    [robot_id: string]: RobotInfo;
}

interface JobData {
    [job_id: string]: JobDetails2;
}

const RobotList : React.FC <RobotListProps> = ({ showRobotList, handleRobotList, onRobotSelect, robots, handleCloseRobotList, searchRobotValue, handleSearchRobotField }) => {
    const [tabValue, setTabValue] = useState(0);
    const handleTabChange = (newValue: number) => {
        setTabValue(newValue);
    }
    const [isShowContent, setIsShowContent] = useState(false)

    useEffect(() => {
      setIsShowContent(showRobotList)
    }, [showRobotList])


    // const robots = listOfRobots.map(robotDetails => new Robot(
    //     "assets/robot.png", 
    //     [robotDetails.startLng, robotDetails.startLat],
    //     robotDetails
    //   ));
    
    //   robots[0].setPath([
    //     [103.7864812268258, 1.2974324150801806],
    //     [103.78694286799777, 1.2972320580376788],
    //     [103.78703488431375, 1.297449566266124],
    //     [103.78676819295936, 1.2975555917762733],
    //     [103.78653347337752, 1.297564167368364],
    //     [103.78646875043523, 1.2976132813503654],
    //     [103.78637049572546, 1.297495561853239],
    //     [103.7864812268258, 1.2974324150801806],
    //   ]);
    
    //   robots[1].setPath([
    //     [103.78634342778514, 1.297401708843907],
    //     [103.78600582329472, 1.2974310072757191],
    //     [103.78599058420178, 1.297345455853133],
    //     [103.7860445071421, 1.297333736479331],
    //     [103.78602926805064, 1.2971919320591638],
    //     [103.78642079547996, 1.297156773936976],
    //     [103.7864430679985, 1.2972681079881028],
    //     [103.78632232750397, 1.297352487476502],
    //     [103.78634342778514, 1.2974040527183206],
    //   ]);

    const listRobots = Object.keys(robots).map(robot_id => {
        const robot = robots[robot_id];
        // return {
        //     name: robot.generalInfo.robot_name,
        //     status: robot.robotStatus?.robot_state,
        //     job: robot.jobs?.length ? robot.jobs[0].job_type : "None",
        // }
        return {
            robotStatus: robot.robotStatus,
            jobs: robot.jobs,
            generalInfo: robot.generalInfo,
        }
    });

    const fuse = new Fuse(listRobots, {
        keys: ["generalInfo.robot_name"],
        threshold: 0.3,
    });

    // Get search results if searchValue is not empty
    const searchResults = searchRobotValue ? fuse.search(searchRobotValue).map(result => result.item) : listRobots;
  
    const filteredRobots = tabValue === 0 
        ? searchResults 
        : searchResults.filter((robot: RobotInfo) => robot.robotStatus?.robot_state === (tabValue === 1 ? "idle" : "busy"));

    console.log("results",searchResults);

    console.log(listRobots);

    // const filteredRobots = tabValue === 0 ? listRobots : tabValue === 1 ? listRobots.filter(robot => robot.robotStatus?.robot_state === "idle") : listRobots.filter(robot => robot.robotStatus?.robot_state === "busy");

    // const filteredRobots = tabValue === 0 ? robots : tabValue === 1 ? robots.filter(robot => robot.status === "Idle") : robots.filter(robot => robot.status === "Delivering");
    const getBatteryIcon = (battery: number, status: string) => {
        if (status === "charging") return <BatteryChargingFullIcon sx={{ color: "#00C48C", fontSize:"40px", rotate:"90deg" }} />;
        if (battery >= 80) return <BatteryFullIcon sx={{ color: "#00C48C", fontSize:"40px", rotate:"90deg" }} />;
        if (battery < 80 && battery >= 50) return <Battery80Icon sx={{ color: "#00C48C", fontSize:"40px", rotate:"90deg" }} />;
        if (battery < 50 && battery >= 30) return <Battery50Icon sx={{ color: "#00C48C", fontSize:"40px", rotate:"90deg" }} />;
        if (battery < 30 && battery > 0) return <Battery30Icon sx={{ color: "#00C48C", fontSize:"40px", rotate:"90deg" }} />;
        if (battery === 0) return <Battery0BarIcon sx={{ color: "#FF0B0B", fontSize:"40px", rotate:"90deg" }} />;
    }
    

    const getRobotStateLabel = (state?: string) => {
      if (!state) {
        return '-'
      }

      switch (state.toLowerCase()) {
        case "busy": 
          return "Busy";

        case "idle":
          return "Idle";

        case "charging":
          return "Charging";

        case "free":
          return "Idle"
          
        case "offline":
          return "Offline";

        case "error":
          return "Error";

        case "stuck":
          return "Stuck";

        case "estop":
          return "Estop";

        case "localisation_error":
          return "Hardware Error";
        default:
          break;
      }
      return state || ""
    }
    return ( 
        <Stack
            sx={{
                position: "absolute",
                top: isShowContent ? "225px" : "210px",
                opacity: isShowContent ? 1 : 0,
                right: "30px",
                width: "400px",
                bgcolor: "white",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                borderRadius: "10px",
                height: "345px",
                overflowY: "hidden",
                overflowX: "hidden",
                transition: 'all',
                transitionDuration: '300ms'
            }}>
                <Box sx={{ boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",  height: "150px", display: "flex", flexDirection: "column" }}>

                <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", m:2, }}>
                    <Typography sx={{ color: "#000", fontSize:"16px", fontWeight:"600", mt:0.5, ml:0.5}}>
                        Robots
                    </Typography>
                    <IconButton onClick={handleCloseRobotList}>
                        <CloseIcon sx={{ color: "#000", fontSize:"20px" }} />
                    </IconButton>
                </Box>
                <TextField
                    id="search-robot"
                    placeholder="Search Robot?"
                    value={searchRobotValue}
                    onChange={handleSearchRobotField}
                    variant="outlined"
                    sx={{
                        width: "92%",
                        m: "auto",
                        '& .MuiInputBase-root': { 
                            height: '50px', 
                            fontSize:"14px",
                        },
                        "& .MuiOutlinedInput-root": {
                            "&.Mui-focused fieldset": {
                                borderColor: "#2C8DFF", 
                            },
                        },
                        mt:-1
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
                <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height:"240px" }}>
                    <Tabs 
                        value={tabValue} 
                        // value = {0}
                        onChange={(event, newValue) => handleTabChange(newValue)}
                        sx={{
                            ml:2,
                            mt:1,
                            fontSize: "8px",
                            '.MuiTabs-indicator': {
                                display: 'none',  
                            },
                            '& .MuiTab-root': {
                                color: '#A0A0A0', 
                                fontSize: '12px',
                                height: '20px', 
                                minHeight: '20px',
                                '&.Mui-selected': {
                                    color: '#087D46', 
                                    backgroundColor: '#00E58E',
                                    borderRadius: '30px',
                                }
                            }
                    }}>
                        <Tab label="All" />
                        <Tab label="Idle" />
                        <Tab label="Busy" />
                    </Tabs>
                </Box>
                </Box>
                <Box sx={{display:"flex", flexDirection:"column", justifyContent:"space-between", mt :1, overflow:"auto"}}>
                    {filteredRobots.map((robot, index) => (
                        <>
                        <Stack
                        // onClick = {(robot: Robot) => onRobotSelect(robot)}
                        // onClick = {() => console.log(robot)}
                        onClick = {() => robot && onRobotSelect(robot)}
                        // onClick = {() => robot.robotStatus && onRobotSelect(robot.robotStatus)}

                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            // mt:2,
                            // mb:2,
                            '&:hover': {
                                bgcolor: "#f9f9f9",
                                cursor: "pointer",
                            }
                        }}
                        // onClick = {() => console.log(robot)}
                        // onClick = {() => onRobotSelect(robot)}
                        >
                            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mt:2, mb:2, ml:4 }} >
                                <Stack sx={{width: "60px", height:"60px", bgcolor: "#f5f5f5", color: "white", fontWeight:"600", borderRadius:"30px", display:"flex", justifyContent:"center", alignItems:"center" }}>
                                    <img src={swiftbot} style={{ width: "40px", height: "40px" }} />
                                </Stack>
                                <Stack sx={{
                                    position: "relative",
                                    top: "-20px",
                                    left: "-20px",
                                    bgcolor: "white",
                                    color: "white",
                                }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center",  alignItems: "center", width:"30px", height:"30px", boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)", borderRadius:"15px", bgColor:"transparent", border: "1px solid #E6E6E6", }}>
                                        <img src={robot.robotStatus?.robot_id === "Delivering"? robotBusy: checked} style={{ width: "20px", height: "20px", borderRadius:"10px",}} />
                                    </Box>
                                </Stack>
                                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "left", ml:2 }}>
                                    <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
                                        <Typography sx={{ color: "#000", fontSize:"16px", fontWeight:"600" }}>
                                            {robot.robotStatus?.robot_name == ''? robot.generalInfo.robot_mac : robot.robotStatus?.robot_name}
                                        </Typography>
                                        <Stack sx={{display: "flex", flexDirection: "row", alignItems: "center" , ml: 3, mt: -1 }}>
                                            {/* <Battery80Icon sx={{ color: "#00C48C", fontSize:"20px", rotate: "90deg" }} /> */}
                                            {robot.robotStatus?.battery && getBatteryIcon(robot.robotStatus?.battery, robot.robotStatus?.robot_state)}
                                            <Stack sx={{position: "relative", top: "0px", left: "-34px"}}>
                                                <Typography sx={{ color: "#252525", fontSize:"12px" }}>
                                                    {robot.robotStatus?.battery}%
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <Typography sx={{ color: "#000", fontSize:"12px" }}>
                                        Current Status: <span style={{ color: robot.robotStatus?.robot_state === "busy"? "#378FFE": robot.robotStatus?.robot_state === "idle" || robot.robotStatus?.robot_state === "free" || robot.robotStatus?.robot_state === "Free" || robot.robotStatus?.robot_state === "charging" ? "#00C48C": "#FF0B0B" }}>
                                            {/* {robot.robotStatus?.robot_state} */}
                                            {getRobotStateLabel(robot.robotStatus?.robot_state)}
                                        </span>
                                    </Typography>
                                </Box>
                            </Box>
                        
                        </Stack>
                        {index !== filteredRobots.length - 1 && <Divider sx={{ width: "85%", margin:"auto", }} />}
                    </>
                    ))}    
                </Box>
                    
            </Stack>

    );
}
 
export default RobotList;
