import { toSecond } from "./_function"
import { EstimatedDataProps, RouteType } from "./index.type"

export const LIFT_TIME = toSecond({ minute: 5 })
export const locationNames = [
  "SSU",
  "ASC",
  "Discharge Pharmacy",
  "Localisation Start Point (B2)",
  "SCH",
  "Lift Lobby D (B2)",
  "Lift Lobby D (L3)",
  "Lift Lobby D (L11)",
  "Lift Lobby D (L13)",
  "Ward 11A",
  "Ward 11B"
] as const

export const estimatedLifts: EstimatedDataProps[] = [
  {
    source: "Lift Lobby D (B2)",
    destination: "Lift Lobby D (L3)",
    estimated_time: LIFT_TIME,
    is_two_way: true
  },
  {
    source: "Lift Lobby D (B2)",
    destination: "Lift Lobby D (L11)",
    estimated_time: LIFT_TIME,
    is_two_way: true
  },
  {
    source: "Lift Lobby D (B2)",
    destination: "Lift Lobby D (L13)",
    estimated_time: LIFT_TIME,
    is_two_way: true
  },
  {
    source: "Lift Lobby D (L3)",
    destination: "Lift Lobby D (L11)",
    estimated_time: LIFT_TIME,
    is_two_way: true
  },
  {
    source: "Lift Lobby D (L3)",
    destination: "Lift Lobby D (L13)",
    estimated_time: LIFT_TIME,
    is_two_way: true
  },
  {
    source: "Lift Lobby D (L11)",
    destination: "Lift Lobby D (L13)",
    estimated_time: LIFT_TIME,
    is_two_way: true
  },
]

export const estimatedDatas: EstimatedDataProps[] = [
  ...estimatedLifts,
  {
    source: "SSU",
    destination: "Lift Lobby D (B2)",
    estimated_time: toSecond({ minute: 1, second: 50 }),
    is_two_way: true
  },
  {
    source: "ASC",
    destination: "Lift Lobby D (L3)",
    estimated_time: toSecond({ minute: 10, second: 30 }),
    is_two_way: true
  },
  {
    source: "ASC",
    destination: "Discharge Pharmacy",
    estimated_time: toSecond({ minute: 7 }),
    is_two_way: true
  },
  {
    source: "Discharge Pharmacy",
    destination: "ASC",
    estimated_time: toSecond({ minute: 7 }),
    is_two_way: true
  },
  {
    source: "Discharge Pharmacy",
    destination: "Lift Lobby D (L3)",
    estimated_time: toSecond({ minute: 15 }),
    is_two_way: true
  },
  {
    source: "Localisation Start Point (B2)",
    destination: "SCH",
    estimated_time: toSecond({ minute: 3, second: 25 }),
    is_two_way: true
  },
  {
    source: "SCH",
    destination: "Lift Lobby D (B2)",
    estimated_time: toSecond({ minute: 2, second: 10 }),
    is_two_way: true
  },
  {
    source: "Localisation Start Point (B2)",
    destination: "Lift Lobby D (B2)",
    estimated_time: toSecond({ minute: 2, second: 10 }),
    is_two_way: true
  },
  {
    source: "Lift Lobby D (L11)",
    destination: "Ward 11A",
    estimated_time: toSecond({ minute: 1, second: 30 }),
    is_two_way: true
  },
  {
    source: "Lift Lobby D (L11)",
    destination: "Ward 11B",
    estimated_time: toSecond({ minute: 1, second: 30 }),
    is_two_way: true
  },
  {
    source: "SSU",
    destination: "SCH",
    estimated_time: toSecond({ minute: 3, second: 50 }),
    is_two_way: true
  },
  {
    source: "SSU",
    destination: "Localisation Start Point (B2)",
    estimated_time: toSecond({ minute: 4, second: 30 }),
    is_two_way: true
  },
  {
    source: "SCH",
    destination: "SSU",
    estimated_time: toSecond({ minute: 3, second: 50 }),
    is_two_way: true
  },
  {
    source: "SCH",
    destination: "Localisation Start Point (B2)",
    estimated_time: toSecond({ minute: 3, second: 50 }),
    is_two_way: true
  },
  {
    source: "Localisation Start Point (B2)",
    destination: "SSU",
    estimated_time: toSecond({ minute: 4, second: 30 }),
    is_two_way: true
  },
]


/**
 * SSU -> ASC
 * Discharge Pharmacy -> Wards 11A
 * Discharge Pharmacy -> Wards 11B
 * Discharge Pharmacy -> SCH
 */
export const altenativeRoutes: RouteType[] = [
  {
    source: "SSU",
    destination: "ASC",
    routes: ["SSU", "Lift Lobby D (B2)", "Lift Lobby D (L3)", "ASC"]
  },
  {
    source: "Discharge Pharmacy",
    destination: "Ward 11A",
    routes: ["Discharge Pharmacy", "Lift Lobby D (L3)", "Lift Lobby D (L11)", "Ward 11A"]
  },
  {
    source: "Discharge Pharmacy",
    destination: "Ward 11B",
    routes: ["Discharge Pharmacy", "Lift Lobby D (L3)", "Lift Lobby D (L11)", "Ward 11B"]
  },
  {
    source: "Discharge Pharmacy",
    destination: "SCH",
    routes: ["Discharge Pharmacy", "Lift Lobby D (L3)", "Lift Lobby D (B2)", "SCH"]
  },
  {
    source: "Discharge Pharmacy",
    destination: "SSU",
    routes: ["Discharge Pharmacy", "Lift Lobby D (L3)", "Lift Lobby D (B2)", "SSU"]
  },
  {
    source: "Discharge Pharmacy",
    destination: "Localisation Start Point (B2)",
    routes: ["Discharge Pharmacy", "Lift Lobby D (L3)", "Lift Lobby D (B2)", "Localisation Start Point (B2)"]
  },
  // === Another Probability ==== //
  // B2 -> L11
  {
    source: "SSU",
    destination: "Ward 11A",
    routes: ["SSU", "Lift Lobby D (B2)", "Lift Lobby D (L11)", "Ward 11A"]
  },
  {
    source: "SSU",
    destination: "Ward 11B",
    routes: ["SSU", "Lift Lobby D (B2)", "Lift Lobby D (L11)", "Ward 11B"]
  },
  {
    source: "Localisation Start Point (B2)",
    destination: "Ward 11A",
    routes: ["Localisation Start Point (B2)", "Lift Lobby D (B2)", "Lift Lobby D (L11)", "Ward 11A"]
  },
  {
    source: "Localisation Start Point (B2)",
    destination: "Ward 11B",
    routes: ["Localisation Start Point (B2)", "Lift Lobby D (B2)", "Lift Lobby D (L11)", "Ward 11B"]
  },
  {
    source: "SCH",
    destination: "Ward 11A",
    routes: ["SCH", "Lift Lobby D (B2)", "Lift Lobby D (L11)", "Ward 11A"]
  },
  {
    source: "SCH",
    destination: "Ward 11B",
    routes: ["SCH", "Lift Lobby D (B2)", "Lift Lobby D (L11)", "Ward 11B"]
  },
  // B2 -> L3
  {
    source: "SCH",
    destination: "ASC",
    routes: ["SCH", "Lift Lobby D (B2)", "Lift Lobby D (L3)", "ASC"]
  },
  {
    source: "Localisation Start Point (B2)",
    destination: "ASC",
    routes: ["Localisation Start Point (B2)", "Lift Lobby D (B2)", "Lift Lobby D (L3)", "ASC"]
  },
  {
    source: "Localisation Start Point (B2)",
    destination: "Discharge Pharmacy",
    routes: ["Localisation Start Point (B2)", "Lift Lobby D (B2)", "Lift Lobby D (L3)", "Discharge Pharmacy"]
  },
  {
    source: "SSU",
    destination: "Discharge Pharmacy",
    routes: ["SSU", "Lift Lobby D (B2)", "Lift Lobby D (L3)", "Discharge Pharmacy"]
  },
  // L3 -> L11
  {
    source: "ASC",
    destination: "Ward 11A",
    routes: ["ASC", "Lift Lobby D (L3)", "Lift Lobby D (L11)", "Ward 11A"]
  },
  {
    source: "ASC",
    destination: "Ward 11B",
    routes: ["ASC", "Lift Lobby D (L3)", "Lift Lobby D (L11)", "Ward 11B"]
  },
  // L3 -> B2
  {
    source: "ASC",
    destination: "SCH",
    routes: ["ASC", "Lift Lobby D (L3)", "Lift Lobby D (B2)", "SCH"]
  },
  {
    source: "ASC",
    destination: "Localisation Start Point (B2)",
    routes: ["ASC", "Lift Lobby D (L3)", "Lift Lobby D (B2)", "Localisation Start Point (B2)"]
  },
  // Random Missing Route
  {
    source: "SSU",
    destination: "Lift Lobby D (L11)",
    routes: ["SSU", "Lift Lobby D (B2)", "Lift Lobby D (L11)"]
  },
  {
    source: "SSU",
    destination: "Lift Lobby D (L3)",
    routes: ["SSU", "Lift Lobby D (B2)", "Lift Lobby D (L3)"]
  },
  {
    source: "SSU",
    destination: "Lift Lobby D (L13)",
    routes: ["SSU", "Lift Lobby D (B2)", "Lift Lobby D (L13)"]
  },
  {
    source: "SCH",
    destination: "Lift Lobby D (L13)",
    routes: ["SCH", "Lift Lobby D (B2)", "Lift Lobby D (L13)"]
  },
  {
    source: "Localisation Start Point (B2)",
    destination: "Lift Lobby D (L13)",
    routes: ["Localisation Start Point (B2)", "Lift Lobby D (B2)", "Lift Lobby D (L13)"]
  },
  {
    source: "ASC",
    destination: "Lift Lobby D (B2)",
    routes: ["ASC", "Lift Lobby D (L3)", "Lift Lobby D (B2)"]
  },
  {
    source: "ASC",
    destination: "Lift Lobby D (L11)",
    routes: ["ASC", "Lift Lobby D (L3)", "Lift Lobby D (L11)"]
  },
  {
    source: "ASC",
    destination: "Lift Lobby D (L13)",
    routes: ["ASC", "Lift Lobby D (L3)", "Lift Lobby D (L13)"]
  },
  {
    source: "Discharge Pharmacy",
    destination: "Lift Lobby D (B2)",
    routes: ["Discharge Pharmacy", "Lift Lobby D (L3)", "Lift Lobby D (B2)"]
  },
  {
    source: "Discharge Pharmacy",
    destination: "Lift Lobby D (L11)",
    routes: ["Discharge Pharmacy", "Lift Lobby D (L3)", "Lift Lobby D (L11)"]
  },
  {
    source: "Discharge Pharmacy",
    destination: "Lift Lobby D (L13)",
    routes: ["Discharge Pharmacy", "Lift Lobby D (L3)", "Lift Lobby D (L13)"]
  },
  {
    source: "Localisation Start Point (B2)",
    destination: "Lift Lobby D (L11)",
    routes: ["Localisation Start Point (B2)", "Lift Lobby D (B2)", "Lift Lobby D (L11)"]
  },
  {
    source: "Localisation Start Point (B2)",
    destination: "Lift Lobby D (L3)",
    routes: ["Localisation Start Point (B2)", "Lift Lobby D (B2)", "Lift Lobby D (L3)"]
  },
  {
    source: "SCH",
    destination: "Lift Lobby D (L3)",
    routes: ["SCH", "Lift Lobby D (B2)", "Lift Lobby D (L3)"]
  },
  {
    source: "SCH",
    destination: "Lift Lobby D (L11)",
    routes: ["SCH", "Lift Lobby D (B2)", "Lift Lobby D (L11)"]
  },
  {
    source: "Ward 11A",
    destination: "Lift Lobby D (B2)",
    routes: ["Ward 11A", "Lift Lobby D (L11)", "Lift Lobby D (B2)"]
  },
  {
    source: "Ward 11A",
    destination: "Lift Lobby D (L3)",
    routes: ["Ward 11A", "Lift Lobby D (L11)", "Lift Lobby D (L3)"]
  },
  {
    source: "Ward 11A",
    destination: "Lift Lobby D (L13)",
    routes: ["Ward 11A", "Lift Lobby D (L11)", "Lift Lobby D (L13)"]
  },
  {
    source: "Ward 11B",
    destination: "Lift Lobby D (B2)",
    routes: ["Ward 11B", "Lift Lobby D (L11)", "Lift Lobby D (B2)"]
  },
  {
    source: "Ward 11B",
    destination: "Lift Lobby D (L3)",
    routes: ["Ward 11B", "Lift Lobby D (L11)", "Lift Lobby D (L3)"]
  },
  {
    source: "Ward 11B",
    destination: "Lift Lobby D (L13)",
    routes: ["Ward 11B", "Lift Lobby D (L11)", "Lift Lobby D (L13)"]
  },
  {
    source: "Ward 11A",
    destination: "Ward 11B",
    routes: ["Ward 11A", "Lift Lobby D (L11)", "Ward 11B"]
  },
]
