import { useEffect, useState } from "react"
import { ApiMethodType, Key, MutationConfigType, RequestConfigType } from "./api.type"
import { ACCESS_TOKEN_KEY, API_URL, PROFILE_KEY } from "./const"
import axios, { AxiosResponse } from "axios"


export const getToken = () => {
  try {
    return (localStorage || window.localStorage).getItem(ACCESS_TOKEN_KEY)
  } catch (error) {
    return undefined
  }
}

export const getProfile = () => {
  try {
    return JSON.parse((localStorage || window.localStorage).getItem(PROFILE_KEY) || '{}')
  } catch (error) {
    return undefined
  }
}

export const defaultRequestConfig: RequestConfigType = {
  noAuth: false,
  enabled: true,
  cache: true,
  revalidate_interval: 3600,
  autoGetChildData: true,
  method: "GET"
}

const getQueryParamFromObj = (data = {}) => {
  const queryParamString = (Object.entries(data)
    .map((item) => (item[1] === undefined ? "" : item[0] + "=" + item[1]))
    .join("&"));
  
  return queryParamString === '' ? '' : '?' + queryParamString
}

const fetchAPI = async (url: string, token: string, method: ApiMethodType = "POST", data: any = {}, headers = {}) => {
  const additonalQueryParam = (method === "POST" ? "" : getQueryParamFromObj(data))
  return await axios({
      method,
      url: url + additonalQueryParam,
      headers: {
        "Authorization": `Bearer ${token}`,
        ...headers
      },
      baseURL: API_URL,
      data,
    }).catch((e) => {
      // When Error
      console.log("error", e.response.data);
    })
}

export const useRequest = <T = any, Data = any>(
  url: string,
  dataArg?: Data,
  configData?: RequestConfigType,
  useAuth: boolean = true
) => {
  const config: RequestConfigType = {
    ...defaultRequestConfig,
    ...configData
  }
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<any>();
  const token = getToken()

  const refetch = async () => {
    if (isLoading) {
      return
    }    
    if (!token && useAuth) {
      return
    }
    setIsLoading(true)
    setError(undefined);
    try {
      // fetch API
      const res = await fetchAPI(url, token as string, configData?.method || "GET", dataArg)
      setData(config.autoGetChildData ? ((res as any).data || res) : res)
      setIsLoading(false)
    } catch (error) {
      setError(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (config.enabled === false) {
      return
    }

    if (!token && useAuth) {
      window.location.href = '/login'
      return
    }
    
    refetch()
  }, [token, config.enabled])

  return { data, isLoading, error, refetch }
}

export const useMutation = <T = any, Params extends Key = Key>(
  url: string,
  configData?: MutationConfigType
) => {
  const config: MutationConfigType = {
    ...defaultRequestConfig,
    ...configData
  }
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<any>();
  const token = getToken()
  
  const trigger = async (params = {} as Params) => {
    if (isLoading) {
      return
    }
    if (!token && !config.noAuth) {
      return
    }
    setIsLoading(true)
    setError(undefined);
    try {
      const res = await fetchAPI(url, token as string, configData?.method || "POST", params)
      const newData = config.autoGetChildData ? ((res as AxiosResponse).data || res) : res
      setData(newData as T)
      setIsLoading(false)
      if (config.onSuccess !== undefined) {
        config.onSuccess(newData as T)
      }
    } catch (error) {
      setError(error)
      setIsLoading(false)
      if (config.onError !== undefined) {
        config.onError(error)
      }
    }

    if (config.onFinish !== undefined) {
      config.onFinish()
    }
  }
  
  return { data, isLoading, error, trigger }
}
