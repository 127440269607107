import axios from "axios";
import { API_URL } from "../../utils/const";
import { getProfile, getToken } from "../../utils/api";

const ENDPOINT_ROBOT_STATUS = "v1/rms/robot_status"
const token = getToken();
const profile = getProfile();
export const getRobotStatus = async() => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}${ENDPOINT_ROBOT_STATUS}/?organisation_id=${profile.organisation}`,
      headers: {
        "Authorization": `Bearer ${token}`,
      },
    });

    return response.data || response
  } catch (error) {
    console.error(error);
  }
}