import mapboxgl, { LngLat, Map, Marker } from "mapbox-gl";
import OverlaySetting from "../models/OverlaySetting";
import React, { useEffect, useMemo } from "react";
import { GIS } from "../RobotMap";
import { createRoot } from "react-dom/client";
import { Box, Typography } from "@mui/material";
import RobotIcon from "../../icons/RobotIcon";
import batteryFull from '../../../assets/batteryFull.png';
import { Robot } from "../models/Robot";
import { useSelector } from "react-redux";
import { OVERLAY_APPEND_KEY } from "../constants";
import * as turf from "@turf/turf";
import { LocationType, LocationTypeProps } from "../../../hooks/location/index.type";
import DoorPointMarker from "../_components/marker/door_point";
import LiftPointMarker from "../_components/marker/lift_point";

// export const useRenderGISElementOnMap = (
//   map: React.MutableRefObject<Map | null>,
//   elements: React.ReactElement<GIS>[]
// ) => {
//   useEffect(() => {
//     if (!map.current) return;

//     const mapboxMap = map.current;
//     let markers: Marker[] = [];

//     // Create markers for each GIS element
//     elements.forEach(elem => {
//       const el = document.createElement("div");
//       el.className = "marker";
//       const marker = new Marker(el).setLngLat(elem.props.GIS).addTo(mapboxMap);
//       markers.push(marker);
//       createRoot(el).render(elem);  // Assuming createRoot and elem are correctly handled in your context
//     });

//     // make sure markers dont move when zooming
//     function updateMarkerSize() {
//       markers.forEach(marker => {
//         const zoom = mapboxMap.getZoom();
//         const scale = Math.pow(2, zoom) / 100;
//         marker.getElement()?.style.setProperty("transform", `scale(${scale})`);
//       });
//     }
//     mapboxMap.on('zoom', updateMarkerSize);
    

//     return () => {
//       mapboxMap.off('zoom', updateMarkerSize); // Clean up event listener
//       markers.forEach(marker => marker.remove()); // Remove markers
//     };
//   }, [elements, map]);
// };

export const useRenderGISElementOnMap = (
  map: React.MutableRefObject<Map | null>,
  elements: React.ReactElement<GIS>[],
  show = true
) => {
  const currentFloor = useSelector((state: any) => state.map.currentFloor);

  useEffect(() => {
    // Do not initialize until map is loaded
    if (!map.current || !show) return;
    const mapboxMap = map.current as Map;
    const markers: Marker[] = [];

    for (const elem of elements) {
      const el = document.createElement("div");
      el.className = "marker";
      markers.push(new Marker(el).setLngLat(elem.props.GIS).addTo(mapboxMap));
      createRoot(el).render(elem);
    }
    console.log("checking 20",elements);

    // return () => {
    //   markers.forEach((marker) => marker.remove());
    // };
    return () => {
      markers.forEach((marker) => marker.remove());
    }
  
  }, [elements, map, currentFloor, show]);
};

export const useRenderPointOnMap = (
  map: React.MutableRefObject<Map | null>,
  dataArray: LocationType[],
  type: LocationTypeProps,
  show = true
) => {
  const currentFloor = useSelector((state: any) => state.map.currentFloor);

  useEffect(() => {
    // Do not initialize until map is loaded
    // Stop render when type is all
    if (!map.current || dataArray.length === 0 || type === 'all' || !show) return;
    const mapboxMap = map.current as Map;
    const markers: Marker[] = [];

    dataArray.forEach((data) => {
      // If map_level is not equal to current Floor then dont render
      if (currentFloor !== data.map[0].map_level.toString()) {
        return
      }
      const el = document.createElement("div");
      let element = undefined;
      el.classList.add("marker");
      el.classList.add(type);
      markers.push(new Marker(el).setLngLat([data.pose.longitude, data.pose.latitude]).addTo(mapboxMap));

      const pointProps = {
        name: data.location_name,
        rotation: data.pose.theta
      }
      switch (type) {
        case 'door_point':
          element = (<DoorPointMarker {...pointProps} />)
          break;

        case 'lift_point': 
          element = (<LiftPointMarker {...pointProps} />)
          break;

        // TODO: Need to add other case
        default:
          return;
      }

      if (element) {
        createRoot(el).render(element);
      }
    });

    return () => {
      markers.forEach((marker) => marker.remove());
    }
  }, [dataArray, map, currentFloor, type, show])
}

export const useRenderRobotOnMap = (
  map: React.MutableRefObject<Map | null>,
  robots: Robot[],
  onRobotClick?: (robot: Robot) => void
) => {
  useEffect(() => {
    // Do not initialize until map is loaded
    if (!map.current) return;
    console.log("checking 22",robots);
    var markers: Marker[] = [];
    const mapboxMap = map.current as Map;
    for (const robot of robots) {
      console.log("checking 23",robot);
      const el = document.createElement("div");
      el.className = "marker";
      el.classList.add("robot-marker")
      el.setAttribute("robotId", robot.robotId);
      createRoot(el).render(
        <Box onClick={() => onRobotClick && onRobotClick(robot)}> 
          <RobotIcon 
            robot={{
              name: robot.robotName,
              status: robot.status,
              battery: robot.batteryLevel,
            }}
          />
        </Box>
      );

      markers.push(new Marker(el).setLngLat(robot.LatLng).addTo(mapboxMap));
    }

    let requestId: number;
    function animateMarker(timestamp: any) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setLngLat(robots[i].LatLng);
        markers[i].setRotation(robots[i].direction);
        // Rotate the robot icon to be upright
        markers[i]
          .getElement()
          ?.children.item(0)
          ?.children.item(0)
          ?.children.item(0)
          ?.setAttribute("style", `transform: rotate(${robots[i].north}deg)`);
        // make sure robot is always on top
        markers[i].getElement()?.style.setProperty("z-index", "1000");
        markers[i].getElement()?.style.setProperty("visiblity", (robots[i].visibility ? "visible" : "hidden"));
        markers[i].getElement()?.style.setProperty("opacity", (robots[i].visibility ? "1" : "0"));
        }

      requestId = requestAnimationFrame(animateMarker);
    }
    requestId = requestAnimationFrame(animateMarker);

    return () => {
      cancelAnimationFrame(requestId);
      markers.forEach((marker) => marker.remove());
    };
  }, [robots, map]);
};


export const useRenderLinesOnMap = (
  map: React.MutableRefObject<Map | null>,
  line: [number, number][],
  lineWidth: number = 8,
  lineColor: string = "#888"
) => {
  useEffect(() => {
    if (!map.current || line.length === 0) return;

    const mapboxMap = map.current as Map;

    mapboxMap.addSource("route", {
      type: "geojson",
      data: {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: line,
        },
      },
    });
    mapboxMap.addLayer({
      id: "route",
      type: "line",
      source: "route",
      layout: {
        "line-join": "round",
        "line-cap": "round",
      },
      paint: {
        "line-color": lineColor,
        "line-width": lineWidth,
      },
    });

    return () => {
      mapboxMap.removeLayer("route");
      mapboxMap.removeSource("route");
    };
  }, [line, map, lineColor, lineWidth]);
};


export const calculateScaledCoordinates = (coordinates: number[][], scaleFactor: number): number[][] => {
  const centroid = turf.centroid({
    type: "Polygon",
    coordinates: [coordinates]
  }).geometry.coordinates

  // Scale the coordinates
  const scaledCoordinates = coordinates.map(coord => [
    centroid[0] + (coord[0] - centroid[0]) * scaleFactor,
    centroid[1] + (coord[1] - centroid[1]) * scaleFactor
  ]);

  return scaledCoordinates
};

export const calculateRotatedCoordinates = (coords: number[][], angle: number) => {
  const rotatedPolygon = turf.transformRotate({
    type: "Polygon",
    coordinates: [coords]
  }, angle);
  return (turf.getCoords(rotatedPolygon))[0].slice(0, 4);
};

export const loadOverlay = (
  map: React.MutableRefObject<Map | null>,
  overlaySetting: OverlaySetting,
  name: string,
  onSelectMap?:({ overlaySetting, name} : { overlaySetting: OverlaySetting, name: string }) => void
) => {
  try {
  const mapboxMap = map.current as Map;

  const currentCoordinates = [
    [overlaySetting.topLeftLng, overlaySetting.topLeftLat],
    [
      overlaySetting.topLeftLng + overlaySetting.widthInGIS,
      overlaySetting.topLeftLat,
    ],
    [
      overlaySetting.topLeftLng + overlaySetting.widthInGIS,
      overlaySetting.topLeftLat - overlaySetting.heightInGIS,
    ],
    [
      overlaySetting.topLeftLng,
      overlaySetting.topLeftLat - overlaySetting.heightInGIS,
    ],
  ];
  console.log("Checking Current Coordinates",name,  currentCoordinates);
  const coordinates = (overlaySetting.rotation ?
    calculateRotatedCoordinates(
      currentCoordinates, overlaySetting.rotation
    ) : currentCoordinates);

  overlaySetting.coordinates = coordinates;

  mapboxMap.addSource(name, {
    type: "image",
    url: overlaySetting.url,
    coordinates: coordinates,
  });

  mapboxMap.addLayer({
    id: name,
    type: "raster",
    source: name,
    paint: {
      "raster-fade-duration": 0,
      "raster-opacity": (overlaySetting.opacity / 100)
    }
  });
  
  // TMP DISABLE MAP
  // const geometryData =
  // {
  //   type: 'geojson' as 'geojson',
  //   data: {
  //     properties: {},
  //     type: 'Feature' as 'Feature',
  //     geometry: {
  //       type: 'Polygon' as 'Polygon',
  //       coordinates: [coordinates]
  //     }
  //   }
  // }

  // mapboxMap.addSource(name + OVERLAY_APPEND_KEY, geometryData)
  // mapboxMap.addLayer({
  //   id: name + OVERLAY_APPEND_KEY,
  //   type: 'fill',
  //   "source": name + OVERLAY_APPEND_KEY,
  //   paint: {
  //     "fill-color": '#1976d2',
  //     "fill-opacity": 0,
  //   }
  // })

  // mapboxMap.on("mousedown", name + OVERLAY_APPEND_KEY, (e) => {
    // overlaySetting.dragging = true
    // overlaySetting.startCoordinates = [e.lngLat.lng, e.lngLat.lat]
    // mapboxMap.dragPan.disable()
    // mapboxMap.doubleClickZoom.disable();
    // onSelectMap?.({ overlaySetting, name})
    // e.preventDefault()
  // });

  // TMP DISABLE
  // mapboxMap.on("mouseenter", name + OVERLAY_APPEND_KEY, (e) => {
  //   if (!overlaySetting.dragging) {
  //     mapboxMap.getCanvas().style.cursor = 'pointer';
  //   }
  // })

  // mapboxMap.on("mouseleave", name + OVERLAY_APPEND_KEY, () => {
  //   mapboxMap.getCanvas().style.cursor = '';
  // })

  // mapboxMap.on("mousemove", (e) => {    
  //   mapboxMap.doubleClickZoom.disable();
  //   if (overlaySetting.dragging && overlaySetting.startCoordinates && overlaySetting.coordinates && overlaySetting.coordinates.length > 0) {
  //     e.preventDefault()

  //     // const coords = e.lngLat;
  //     mapboxMap.getCanvas().style.cursor = 'grabbing';

  //     // Count the drag distance
  //     const dx = (e.lngLat.lng - overlaySetting.startCoordinates[0]);
  //     const dy = (e.lngLat.lat - overlaySetting.startCoordinates[1]);

  //     // set the new coordinate after addition in (x & y) coordinate
  //     const newCoordinates = overlaySetting.coordinates.map(([lng, lat]) => [lng + dx, lat + dy]);

  //     // geometryData.data.geometry.coordinates = [newCoordinates];
  //     (mapboxMap.getSource(name) as mapboxgl.ImageSource).setCoordinates(newCoordinates);
  //     (mapboxMap.getSource(name + OVERLAY_APPEND_KEY) as mapboxgl.GeoJSONSource).setData({
  //       type: "Feature",
  //       properties: {},
  //       geometry: {
  //         type: 'Polygon',
  //         coordinates: [newCoordinates],
  //       },
  //     })
  //   }
  // })


  // const onStopDragging = (lngLat?: number[]) => {
  //   mapboxMap.doubleClickZoom.enable();
  //   if (overlaySetting.dragging) {      mapboxMap.dragPan.enable()
  //     overlaySetting.dragging = false
  //     if (lngLat && overlaySetting.startCoordinates && overlaySetting.coordinates) {
  //       const dx = (lngLat[0] - overlaySetting.startCoordinates[0]);
  //       const dy = (lngLat[1] - overlaySetting.startCoordinates[1]);
  //       // set the new coordinate after addition in (x & y) coordinate
  //       const newCoordinates = overlaySetting.coordinates.map(([lng, lat]) => [lng + dx, lat + dy]);
  //       overlaySetting.coordinates = newCoordinates;
  //     }
  //     overlaySetting.startCoordinates = undefined
  //     mapboxMap.getCanvas().style.cursor = '';
  //   }
  // }

  // mapboxMap.on("mouseup", name + OVERLAY_APPEND_KEY, (e) => {
  //   onStopDragging([e.lngLat.lng, e.lngLat.lat])
  // })

  // mapboxMap.on("click", (e) => {
  //   if (overlaySetting.dragging) {
  //     onStopDragging([e.lngLat.lng, e.lngLat.lat])
  //   }
  // })
  } catch (error) {}
};

export const removeOverlay = (
  map: React.MutableRefObject<Map | null>,
  name: string
) => {
  const mapboxMap = map.current as Map;
  mapboxMap.removeLayer(name);
  mapboxMap.removeSource(name);
}

